import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Card, Col, Container, Image, Row } from 'react-bootstrap';

import { Header } from '../../components/Header/Header';

import './AboutScreen.scss';
import { dominique } from '../../utils/images';

export const AboutScreen: React.FC = () => {

  return (
    <section>
      <Header/>
      <section>
        <Container className={ 'purpose-container' }>
          <Row className={ 'top-container align-items-center purpose-fade-in' }>
            <Col sm={ 12 } className={ 'font-primary d-flex justify-content-start' }>
              <h1>
                About
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={ 'purpose-fade-in' }>
        <Container className={ 'pb-5' }>
          <Row className={ 'mt-4' }>
            <Col xs={ 12 } sm={ 7 } className={ 'bg-light text-start p-4 d-flex flex-column h-100' }>
              <h3>
                Our strong foundation
              </h3>
              <div className={ 'mt-4' }>
                Intramark<span className={ 'font-primary' }>^</span> began with a bold mission: to empower leaders to
                'turn on the lights' and discover hidden
                factors that drive organizational success. Today, we partner with forward-thinking organizations,
                delivering measurable outcomes through the dedication and expertise of our exceptional team.
              </div>
              <div className={ 'mt-5' }>
                <strong>To our valued customers:</strong> Your trust fuels our commitment to excellence. We’re honored
                to help you achieve impactful results, and together, we’re building something remarkable.
              </div>
              <div className={ 'mt-5' }>
                <strong>Thank You!</strong>
              </div>
              <div>
                Sincerely,
                <br/>
                Dominique St-Fleur
                <br/>
                Founder, CEO<span className={ 'font-primary' }><strong>^</strong></span>
              </div>
            </Col>
            <Col xs={ 12 } sm={ 1 }></Col>
            <Col xs={ 12 } sm={ 4 } className={ 'd-flex flex-column mt-5 mt-sm-0' }>
              <Col>
                <Image src={ dominique } width={ '70%' }/>
                <h3 className={ 'font-primary mt-3' }>Dominique St-Fleur</h3>
                <h5><strong>Chief Executive</strong></h5>
                <div className={ 'd-flex gap-4 justify-content-center mt-2' }>
                  <a href={ 'https://www.linkedin.com/in/dominiquestfleur/' } target={ '_blank' }>
                    <FontAwesomeIcon icon={ faLinkedin } className={ 'bg-light p-3 fs-5 rounded cursor-pointer' }/>
                  </a>
                  <a href={ 'mailto:dstfleur@intramark.io' }>
                    <FontAwesomeIcon icon={ faEnvelope } className={ 'bg-light p-3 fs-5 rounded cursor-pointer' }/>
                  </a>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  )
}