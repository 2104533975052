import React, { useState } from 'react';
import { Badge, Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { Header } from '../../components/Header/Header';
import { Subscribe as SubscribeModel } from '../../models/Subscribe';
import SubscribeService from '../../services/SubscribeService';

export const SubProcessorsScreen: React.FC = () => {

  const [email, setEmail] = useState('');
  const [showSuccess, toggleShowSuccess] = useState(false);
  const [showError, toggleShowError] = useState(false);

  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      await subscribeToUpdates();
    }
  }
  const subscribeToUpdates = async () => {
    try {
      const subscription: SubscribeModel = {
        email
      };
      await SubscribeService.saveSubscription(subscription);
      toggleShowSuccess(true);
      setTimeout(() => {
        toggleShowSuccess(false);
      }, 4000);

      setEmail('');
      setValidated(false);
    } catch (error) {
      toggleShowError(true);
      setTimeout(() => {
        toggleShowError(false);
      }, 4000);
    }
  }

  return (
    <section>
      <Header/>
      <Container className={ 'pt-4 pb-5' }>
        <Row className={ 'justify-content-center' }>
          <Col sm={ 6 } className={ 'text-start' }>
            <div className={ 'mt-4' }>
              <h3><strong>Sub-Processors</strong></h3>
            </div>
            <div>
              <Badge bg={ 'light' } text={ 'secondary' }>
                Last Updated: February 15, 2023, effective as of February 15, 2023
              </Badge>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <div>
              In this document, we maintain a current list of Sub-Processors authorized to process customer data in
              order to provide Intramark’s digital platforms in accordance with the applicable service agreement.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } >
            <div className={ 'text-justify' }>
              Intramark uses the following Sub-Processors for hosting and providing Intramark’s digital platforms:
            </div>
            <div className={'mt-3 text-start'}>
              <Table bordered responsive>
                <thead className={'bg-dark font-white'}>
                <tr>
                  <td className={'p-3'}>Sub-Processor</td>
                  <td className={'p-3'}>Entity Country</td>
                  <td className={'p-3'}>Purpose Description</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className={'p-3'}>Amazon Web Services Inc.</td>
                  <td className={'p-3'}>Washington, United States</td>
                  <td className={'p-3'}>Hosting infrastructure services</td>
                </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } >
            <div className={ 'text-justify' }>
              The following Intramark affiliates may function as Sub-Processors in the course of providing Intramark’s
              digital platforms:
            </div>
            <div className={'mt-3 text-start'}>
              <Table bordered responsive>
                <thead className={'bg-dark font-white'}>
                <tr>
                  <td className={'p-3'}>Intramark Entity</td>
                  <td className={'p-3'}>Location</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className={'p-3'}>Intramark Modal LLC</td>
                  <td className={'p-3'}>United States</td>
                </tr>
                <tr>
                  <td className={'p-3'}>Intramark Services LLC</td>
                  <td className={'p-3'}>United States</td>
                </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className={ 'pb-5' }>
        <Row className={ 'justify-content-center' }>
          <Col sm={ 6 }>
            <div className={ 'mt-4' }>
              <h5><strong>Subscribe to Sub-processors Changes</strong></h5>
            </div>
            <div className={'text-justify mt-3'}>
              The list of Sub-Processors Intramark engages may change from time to time as Intramark’s digital
              platforms evolve. We suggest checking this page regularly and to subscribe to receive automated email
              notifications on proposed new, or replacement of existing Sub-Processors.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={3}>
            <div className={ 'mt-4' }>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Control
                  placeholder="Email address"
                  aria-label="Email address"
                  aria-describedby="basic-addon2"
                  type={'email'}
                  value={email}
                  required
                  onChange={(event) => setEmail(event.target.value)}
                />
                <Button variant="primary" type={'submit'} className={'mt-4'}>
                  Submit
                </Button>
                <Form.Control.Feedback type="invalid">
                  Please enter your valid email address.
                </Form.Control.Feedback>
              </Form>
              {showSuccess && (
                <Form.Text id="passwordHelpBlock" className={'text-primary fade-in'}>
                  You are subscribed for updates.
                </Form.Text>
              )}
              {showError && (
                <Form.Text id="passwordHelpBlock" className={'text-danger fade-in'}>
                  An error occurred while subscribing. Please try again later!
                </Form.Text>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}