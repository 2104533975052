import { Subscribe } from '../models/Subscribe';
import request from '../utils/axios';

const SUBSCRIBE_ENDPOINT = '/subscribe';

const UNSUBSCRIBE_ENDPOINT = '/unsubscribe';

export default class SubscribeService {

  static saveSubscription = (subscribe: Subscribe) => {
    return request({
      url: SUBSCRIBE_ENDPOINT,
      method: 'post',
      data: { ...subscribe },
    });
  };

  static unsubscribe = (subscribe: Subscribe) => {
    return request({
      url: UNSUBSCRIBE_ENDPOINT,
      method: 'post',
      data: { ...subscribe },
    });
  };
}