import React from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { Header } from '../../components/Header/Header';

export const SecurityScreen: React.FC = () => {
  return (
    <section>
      <Header/>
      <Container className={ 'pt-4 pb-5' }>
        <Row className={ 'justify-content-center' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <div className={ 'mt-4' }>
              <h3><strong>Security and Data Privacy</strong></h3>
            </div>
            <div>
              <Badge bg={ 'light' } text={ 'secondary' }>Last Updated: February 15, 2023</Badge>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <div>
              We take security very seriously. Intramark applies a robust approach to maintain privacy and security
              controls, and is committed to the highest market standards and compliance regulations to ensure the
              continued trust of our customers around the globe.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>OUR SECURITY</strong></h6>
            <div>
              <div>
                Securing our customer’s information is our top priority. We believe that great privacy rests on great
                security.
              </div>
              <div className={ 'mt-3' }>
                We use technical, contractual, and operational safeguards to protect your data, taking into account the
                nature of the personal data, and the threats posed. We are constantly working to improve on these
                safeguards to help keep our customer’s personal data secure.
              </div>
            </div>
            <div className={ 'mt-3' }>
              <strong>Confidentiality</strong>
              <div>
                Intramark utilizes best-of-breed authentication, encryption, access control systems and configurations
                to protect against unauthorized access.
              </div>
            </div>
            <div className={ 'mt-3' }>
              <strong>Integrity</strong>
              <div>
                At Intramark we verify that all information remains intact, and ensure it is kept in its original
                accurate and complete form. We monitor checks and control data integrity across the data’s entire
                lifecycle.
              </div>
            </div>
            <div className={ 'mt-3' }>
              <strong>Availability and Performance</strong>
              <div>
                Intramark ensures content, at any scale and capacity, is resilient to all changes that may occur, and is
                being delivered at speed and accessible to the end user seamlessly at any time, anywhere on the globe.
              </div>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>YOUR PRIVACY</strong></h6>
            <div className={ 'mt-3' }>
              <strong>
                Your Data Your Choice
              </strong>
              <div>
                At Intramark, we respect your rights to your own data, giving you full control over data collection to
                support your data rights. You choose what level of data will be collected - and corresponding controls.
                Read our Privacy Policy: <a href={ 'privacy-policy' }>https://www.intramark.io/privacy-policy</a>
              </div>
            </div>
            <div className={ 'mt-3' }>
              <strong>
                Data Residency
              </strong>
              <div>
                Data is stored and accessed according to all legal jurisdictional considerations. Intramark allows you
                to specify which of our geographic locations your data will be stored at.
              </div>
            </div>
            <div className={ 'mt-3' }>
              <strong>
                Data Protection
              </strong>
              <div>
                Intramark encrypts data in transit; data at rest stored in secure AWS Cloud instances.
                See Application Installation and Security section below for detail.
              </div>
            </div>
            <div className={ 'mt-3' }>
              <strong>
                Data Retention
              </strong>
              <div>
                Intramark is transparent about the data in use and allows customers to take full control of information
                collected. Analytics data can be deleted or anonymized by request. Requests to delete or anonymize
                analytics data are handled within 90 days.
              </div>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>APPLICATION INSTALLATION AND SECURITY</strong></h6>
            <div>
              At Intramark, we understand the importance of application installation and security. When it comes to
              installing applications, we ensure a seamless and user-friendly experience. We carefully
              develop and design applications that are easy to install, allowing you to quickly and efficiently
              incorporate our solutions into your business operations. We prioritize security and take all necessary
              measures to protect your data and sensitive information. You can read more about
              Application Installation and Security
              on <a href={'/app-installation'}>https://intramark.io/app-installation.</a>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>REPORT AN ISSUE</strong></h6>
            <div>
              Intramark continuously monitors the threat landscape, resolving incidents and applying changes to ensure
              the highest levels of security protection across all products and services.
            </div>
            <div className={ 'mt-3' }>
              <strong>Security Reporting</strong>
              <div>
                Intramark takes security issues seriously and is committed to protecting our customers’ data. If you
                have found a security issue, please contact the Intramark
                Security Team: <a href={ 'mailTo:privacy@intramark.io' }>privacy@intramark.io</a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}