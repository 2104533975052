import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const Children: React.FC<{elemRef: any}> = ({ elemRef }) => {

  const scrollToElement = () => {
    elemRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start'});
  }

  return (
    <Row className={ 'justify-content-center mt-5' }>
      <Col sm={ 6 } className={ 'text-justify' }>
        <h6><strong>VIII. Children</strong></h6>
        <div>
          Intramark’s websites are not directed at children. We do not knowingly collect Personal Data from children
          under the age of 16. If you are a parent or guardian and believe that your child has provided us with Personal
          Data without your consent, please contact us by using the information in
          the “<span className={ 'link-text' } onClick={ scrollToElement }>How to Contact Us</span>” section,
          below, and we will take steps to delete such Personal Data from our systems.
        </div>
      </Col>
    </Row>
  )
}