import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const DataRetention: React.FC = () => {
  return (
    <Row className={ 'justify-content-center mt-5' }>
      <Col sm={ 6 } className={ 'text-justify' }>
        <h6><strong>IX. How Long Does Intramark Keep Your Data?</strong></h6>
        <div>
          We will retain your Personal Data for a period of time that is consistent with the original purpose of the
          data collection, or as necessary to comply with our legal obligations, resolve disputes, and enforce our
          agreements. We determine the appropriate retention period for Personal Data by considering the amount, nature
          and sensitivity of your Personal Data processed, the potential risk of harm from unauthorized use or
          disclosure of your Personal Data and whether we can achieve the purposes of the processing through other
          means, and on the basis of applicable legal requirements (such as applicable statutes of limitation).
        </div>
      </Col>
    </Row>
  )
}