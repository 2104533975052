import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const InformationChoices: React.FC = () => {
  return (
    <Row className={ 'justify-content-center mt-5' }>
      <Col sm={ 6 } className={ 'text-justify' }>
        <h6><strong>X. Your Information Choices</strong></h6>
        <div className={ 'mt-3' }>
          <h6><strong>Your Privacy Choices</strong></h6>
          <div>
            In the above sections, we describe how we may collect, use and share your Personal Data for providing
            relevant
            content and advertising. Below, we describe how you may unsubscribe, opt out, or otherwise modify settings
            related to our processing of your Personal Data.
          </div>
          <div className={ 'mt-3' }>
            Depending on your state of residence (for example, if you are a California resident), you have the right to
            opt-out of the sale or sharing of your Personal Data by us as a business. Intramark shares Personal Data as
            further described below in Section XI, which may be considered a “sale” of Personal Data under the
            California
            Consumer Privacy Act. You may opt out by clicking the “Your Privacy Choices” link at the bottom of our
            website and selecting your preferences on that page.
          </div>
        </div>
        <div className={ 'mt-3' }>
          <h6><strong>Direct Email Marketing.</strong></h6>
          <div>
            If you wish to withdraw from direct email marketing communications from Intramark, you may click the
            “unsubscribe” button included in our emails or:
          </div>
          <div className={ 'mt-3' }>
            <ul>
              <li>
                Please request to do so through our <a href={ '/unsubscribe' }>form</a>. Please note, you cannot
                unsubscribe from critical
                transactional emails that are related to our provision of our online Service (such as those related to
                security and your Intramark account).
              </li>
              <li className={ 'pt-1' }>
                For Intramark Consumer Products, please request to do so through our form.
              </li>
            </ul>
          </div>
        </div>
        <div className={ 'mt-3' }>
          <strong>Direct Marketing – Phone or Postal Mailings</strong>. If you wish to withdraw from phone call or
          postal mail marketing communications from Intramark, please request to do so through our <a
          href={ '/contact' }>form</a>.
        </div>
        <div className={ 'mt-3' }>
          <strong>Analytics</strong>. To opt-out of analytics on our websites, you may adjust your cookie preferences
          as described below. For more information on how to opt-out of tracking technology from Google Analytics,
          please request to do so through our form. To opt-out of Marketo’s tracking technology, please request to do
          so through our <a href={ '/unsubscribe' }>form</a>.
        </div>
        <div className={ 'mt-3' }>
          If you are a user of the Intramark online service via a subscription purchased for you by an Intramark
          customer, to opt-out of platform-based analytics on an individual level, including participation in product
          feedback programs, please contact us at <a href={ 'mailTo:privacy@intramark.io' }>privacy@intramark.io</a> or
          please make a request for this through our <a href={ '/unsubscribe' }>form</a>.
        </div>
        <div className={ 'mt-3' }>
          <strong>Cookie Preferences</strong>. To manage the use of relevant advertising and other non-essential
          cookies, please see details below:
          <div className={ 'mt-3' }>
            <ul>
              <li>
                Please make a request through our <a href={ '/unsubscribe' }>form</a> to opt-out of relevant advertising
                and other non-essential
                cookies. (You may need to adjust your browser or cookie settings to access if you have already made a
                choice to accept cookies.)
              </li>
              <li className={ 'pt-1' }>
                You may also adjust your web browser settings to opt-out of non-essential
                cookies. Please understand that blocking or deleting non-essential cookies may affect our websites’
                functionality.
              </li>
              <li className={ 'pt-1' }>
                If you came to our websites from personalized advertising, then you may further
                opt out of interest-based advertising from our advertising vendors through the Digital Advertising
                Alliance by using the following, applicable link(s):
                (i) <a href={ 'https://optout.aboutads.info/?c=2&lang=EN' }>USA</a>;
                (ii) <a href={ 'https://www.youronlinechoices.com/' }>Europe</a>;
                (iii) <a href={ 'https://youradchoices.ca/' }>Canada</a>; and
                (iv) <a href={ 'https://optout.aboutads.info/?c=2&lang=EN' }>Other regions</a>.
              </li>
            </ul>
          </div>
          <div className={ 'mt-4' }>
            Note that any choice with regards to cookie-based advertising only applies to the web browser through which
            you exercise that choice. You will continue to see advertising, including potentially from Intramark, even
            if you opt-out of personalized advertising.
          </div>
        </div>
        <div className={ 'mt-3' }>
          <h6><strong>Your Privacy Rights</strong></h6>
          <div>
            Depending on your jurisdiction, you may have certain rights with respect to your Personal Data that we
            process in our capacity as a data controller, subject to applicable law:
          </div>
          <div className={ 'mt-3' }>
            <strong>Right to Access</strong>. You have the right to access your Personal Data held by us.
          </div>
          <div className={ 'mt-3' }>
            <strong>Right to Rectification</strong>. You have the right to rectify inaccurate Personal Data and, taking
            into account the purpose of processing, to ensure it is complete.
          </div>
          <div className={ 'mt-3' }>
            <strong>Right to Erasure (or “Right to be Forgotten”)</strong>. You have the right to have your Personal
            Data erased or deleted.
          </div>
          <div className={ 'mt-3' }>
            <strong>Right to Restrict Processing</strong>. You have the right to restrict our processing of your
            Personal Data.
          </div>
          <div className={ 'mt-3' }>
            <strong>Right to Data Portability</strong>. You have right to transfer your Personal Data, when possible.
          </div>
          <div className={ 'mt-3' }>
            <strong>Right to Object</strong>. You have the right to object to the processing of your Personal Data that
            is carried out on the basis of legitimate interests, such as direct marketing.
          </div>
          <div className={ 'mt-3' }>
            <strong>Right Not to be Subject to Automated Decision-Making</strong>. You have the right not to be subject
            to automated decision-making, including profiling, which produces legal effects. Intramark does not
            currently engage in the foregoing on our websites or in our products and services.
          </div>
          <div className={ 'mt-3' }>
            If you would like to make a request and exercise your rights described above or have questions or concerns,
            please complete our online <a href={ '/contact' }>form</a> or reach out to us using the contact information
            below. You also have the
            right to lodge a complaint with your relevant supervisory authority.
          </div>
        </div>
      </Col>
    </Row>
  )
}