import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const Roles: React.FC = () => {
  return (
    <Row className={ 'justify-content-center mt-5' }>
      <Col sm={ 6 } className={ 'text-justify' }>
        <h6><strong>II. Intramark’s Roles & Responsibilities</strong></h6>
        <div>
          Intramark is the controller of your Personal Data, as described in this Privacy Policy, unless otherwise
          stated. Please note that this Privacy Policy does not apply to the extent that we process Personal Data
          in the role of a processor (or a comparable role such as a “service provider” in certain jurisdictions)
          on behalf of our customers, including where we offer to our customers various products and services,
          through which our customers (and/or their affiliates) connect their own websites and applications to our
          platform, sell or offer their own products and services, send electronic communications to other
          individuals, or otherwise collect, use, share or process Personal Data via our products and services.
        </div>
        <div className={ 'mt-3' }>
          Each of our customers, not Intramark, controls whether they provide you with an account or other access
          to the Intramark service through their subscription, and if they provide you with such accounts or other
          access through their subscription, they control what information about you that they submit to our
          service. This content may include contact information (such as your first and last name), professional
          information (such as the department you work for at your place of employment), or other types of
          information that a customer chooses to submit. Use of this content by Intramark is governed by agreements
          between Intramark and the Customer.
        </div>
        <div className={ 'mt-3' }>
          For detailed privacy information applicable to situations where an Intramark customer
          (and/or a customer affiliate) who uses Intramark’s products and services is the controller, please reach
          out to the respective customer directly. We are not responsible for the privacy or data security
          practices of our customers, which may differ from those set forth in this Privacy Policy. If not stated
          otherwise either in this Privacy Policy or in a separate disclosure, we process such Personal Data in the
          role of a processor or service provider on behalf of a customer (and/or its affiliates), who is the
          responsible controller of the applicable Personal Data.
        </div>
        <div className={ 'mt-3' }>
          If your Personal Data has been submitted to us by or on behalf of an Intramark customer and you wish to
          exercise any rights you may have under applicable data protection laws, please inquire with the
          applicable customer directly. Because we may only access a customer’s data upon instruction from that
          customer, if you wish to make your request directly to us, please provide to us the name of the Intramark
          customer who submitted your Personal Data to us. If we are able to verify the Intramark customer,
          we will refer your request to that customer and support them as needed in responding to your request
          within a reasonable timeframe.
        </div>
        <div className={ 'mt-3' }>
          Additional information and safeguards regarding Intramark’s data protection obligations
          (including for international transfers) to our customers are set forth in our Security and Data Privacy
          documentation, which is available at <a href={ '/agreements' }>https://www.intramark.io/agreements.</a>
        </div>
      </Col>
    </Row>
  )
}