import React from 'react';
import { Card, Carousel, Col, Container, Image, Row } from 'react-bootstrap';
import { Header } from '../../components/Header/Header';
import './VibeScreen.scss';
import { Subscribe } from '../../components/Subscribe/Subscribe';
import {
  bellOn,
  chartMixed,
  cloud,
  codeCompare,
  comment,
  modalWindow,
  pieChart,
  sunBright,
  webhook,
} from '../../utils/images';

export const VibeScreen: React.FC = () => {

  return (
    <section>
      <Header/>
      <section className={'fade-in'}>
        <Container className={ 'main-container' }>
          <Row className={ 'align-items-center' }>
            <Col sm={ 6 } xs={12} className={ 'justify-content-center mb-md-0 mb-5' }>
              <Carousel
                fade
                interval={ 3000 }
                indicators={ false }
              >
                <Carousel.Item>
                  <Image
                    className="d-block w-100"
                    src={ modalWindow }
                    alt="First slide"
                  />
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col sm={ 6 } xs={12} className={ 'text-start' }>
              <div>
                <h2>Real-time, actionable feedback prompts</h2>
                <div className={ 'mt-4' }>
                  Prompts delivered through our Vibe<span
                  className={ 'font-primary' }>^</span> application as a creative solution to maximize participation and
                  to capture the employee experience in real-time.
                  Individual employee responses to prompts are sent directly to Intramark<span
                  className={ 'font-primary' }>^</span>
                  to ensure confidentiality; aggregate response analytics and insights are shared with employees
                  and leadership at your organization.
                </div>
                <Row className={ 'mt-4' }>
                  <Col xs={ 2 } className={ 'd-flex justify-content-center align-items-center icon-border-right' }>
                    <Image
                      src={ chartMixed }
                      width={ 40 }
                    />
                  </Col>
                  <Col xs={ 10 }>
                    Actionable feedback prompts to capture employee experience in real-time
                  </Col>
                </Row>
                <Row className={ 'mt-4' }>
                  <Col xs={ 2 } className={ 'd-flex justify-content-center align-items-center icon-border-right' }>
                    <Image
                      src={ bellOn }
                      width={ 40 }
                    />
                  </Col>
                  <Col xs={ 10 }>
                    Early detection to act on workforce engagement, systemic culture issues,
                    best practices to crosspollinate across teams, among many other opportunities/challenges
                  </Col>
                </Row>
                <Row className={ 'mt-4' }>
                  <Col xs={ 2 } className={ 'd-flex justify-content-center align-items-center icon-border-right' }>
                    <Image
                      src={ sunBright }
                      width={ 40 }
                    />
                  </Col>
                  <Col xs={ 10 }>
                    Feedback prompts and response choices designed to enable discernable follow-up/actions
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={ 'bg-light fade-in' }>
        <Container className={ 'main-container' }>
          <Row className={ 'text-start' }>
            <Col sm={ 4 } xs={12} className={ 'wrapper mb-md-0 mb-5' }>
              <div className={ 'title-col' }>
                <h2>How customers use Vibe</h2>
              </div>
            </Col>
            <Col sm={ 4 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
              <Card>
                <Card.Title className={ 'text-center p-2 pt-5' }>
                  <Image
                    src={ comment }
                    width={ 46 }
                  />
                </Card.Title>
                <Card.Body>
                  <div className={ 'text-center' }>
                    <h5><strong>Capture the employee experience in real-time</strong></h5>
                    <span>Actionable prompts to employees in workforce, department, or by functional role to evaluate workforce engagement, assess turnover risk, identify systemic cultural opportunities and issues, among other areas</span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={ 4 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
              <Card className={ 'h-100' }>
                <Card.Title className={ 'text-center p-2 pt-5' }>
                  <Image
                    src={ codeCompare }
                    width={ 38 }
                  />
                </Card.Title>
                <Card.Body>
                  <div className={ 'text-center' }>
                    <h5><strong>Measure project experience in real-time</strong></h5>
                    <span>Actionable prompts to specific project teams to evaluate and compare employee experience across different projects</span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className={ 'main-container' }>
          <Row className={ 'align-items-center section-three-wrapper' }>
            <Col sm={ 6 } xs={12} className={ 'justify-content-center image-carousel mb-md-0 mb-5' }>
              <Carousel
                fade
                interval={ 3000 }
                indicators={ false }
              >
                <Carousel.Item>
                  <Image
                    className="d-block w-100"
                    src={ modalWindow }
                    alt="First slide"
                  />
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col sm={ 6 } className={ 'text-start image-info' }>
              <div>
                <h2>Data Security & Privacy</h2>
                <div className={ 'mt-4' }>
                  Securing your data is our top priority. We believe that great privacy rests on great security. Your
                  individual response data to prompts are:
                </div>
                <Row className={ 'mt-4' }>
                  <Col xs={ 2 } className={ 'd-flex justify-content-center align-items-center icon-border-right' }>
                    <Image
                      src={ cloud }
                      width={ 44 }
                    />
                  </Col>
                  <Col xs={ 10 }>
                    Stored in Intramark<span className={ 'font-primary' }>^</span> Amazon Web Services (AWS) cloud
                    instances
                  </Col>
                </Row>
                <Row className={ 'mt-4' }>
                  <Col xs={ 2 } className={ 'd-flex justify-content-center align-items-center icon-border-right' }>
                    <Image
                      src={ pieChart }
                      width={ 36 }
                    />
                  </Col>
                  <Col xs={ 10 }>
                    Restricted to aggregated data analytics for anyone at your organization
                  </Col>
                </Row>
                <Row className={ 'mt-4' }>
                  <Col xs={ 2 } className={ 'd-flex justify-content-center align-items-center icon-border-right' }>
                    <Image
                      src={ webhook }
                      width={ 36 }
                    />
                  </Col>
                  <Col xs={ 10 }>
                    Integrate data analytics with existing systems/software securely via APIs
                  </Col>
                </Row>
                <div className={ 'mt-5' }>
                  Please see <a href={ '/security' }>Security & Data Privacy</a> for more information on our data
                  security and privacy practices.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={ 'bg-light' }>
        <Subscribe/>
      </section>
    </section>
  )
}