import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Header } from '../../components/Header/Header';
import { Subscribe as SubscribeModel } from '../../models/Subscribe';
import SubscribeService from '../../services/SubscribeService';

export const Unsubscribe: React.FC = () => {

  const [email, setEmail] = useState('');
  const [emailPreference, toggleEmailPreference] = useState(false);
  const [adPreference, toggleAdPreference] = useState(false);
  const [marketoTracking, toggleMarketoTracking] = useState(false);
  const [showSuccess, toggleShowSuccess] = useState(false);
  const [showError, toggleShowError] = useState(false);

  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      await unsubscribe();
    }
  }
  const unsubscribe = async () => {
    try {
      const subscription: SubscribeModel = {
        email,
        emailPreference,
        adPreference,
        marketoTracking,
      };
      await SubscribeService.unsubscribe(subscription);
      toggleShowSuccess(true);
      setTimeout(() => {
        toggleShowSuccess(false);
      }, 4000);

      setEmail('');
      toggleEmailPreference(false);
      toggleAdPreference(false);
      toggleMarketoTracking(false);
      setValidated(false);
    } catch (error) {
      toggleShowError(true);
      setTimeout(() => {
        toggleShowError(false);
      }, 4000);
    }
  }

  return (
    <section>
      <Header />
      <Container className={'pt-3'} fluid={'sm'}>
        <Row className={'justify-content-center align-items-center'}>
          <Col sm={4} className={'text-start'}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email <sup className={'text-danger'}>*</sup></Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your valid email address.
                </Form.Control.Feedback>
              </Form.Group>
              <div className={'mt-5'}>
                <h4>Email Preferences:</h4>
                <Form.Check
                  id={'email-preference'}
                  checked={emailPreference}
                  onChange={() => toggleEmailPreference(!emailPreference)}
                  type={'checkbox'}
                  label={'Unsubscribe from all email communications.'}
                />
              </div>
              <div className={'mt-5'}>
                <h4>Global Preferences:</h4>
                <Form.Check
                  checked={adPreference}
                  type={'checkbox'}
                  id={'ad-preference'}
                  onChange={() => toggleAdPreference(!adPreference)}
                  label={<span>Opt out of relevant ads by third parties using email</span>}
                />
                <Form.Check
                  checked={marketoTracking}
                  type={'checkbox'}
                  id={'marketo-tracking'}
                  onChange={() => toggleMarketoTracking(!marketoTracking)}
                  label={<span>Opt out of Marketo tracking</span>}
                />
              </div>
              <Button variant="primary" type={'submit'} className={'mt-5 text-center'}>
                Submit
              </Button>
            </Form>
            {showSuccess && (
              <Form.Text id="passwordHelpBlock" className={'text-primary fade-in'}>
                Your preferences have been updated.
              </Form.Text>
            )}
            {showError && (
              <Form.Text id="passwordHelpBlock" className={'text-danger fade-in'}>
                An error occurred while un-subscribing. Please try again later!
              </Form.Text>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}