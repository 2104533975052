import React from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { Header } from '../../components/Header/Header';

export const CopyrightScreen: React.FC = () => {
  return (
    <section>
      <Header />
      <Container className={'pt-4 pb-5'}>
        <Row className={'justify-content-center'}>
          <Col sm={6} className={'text-justify'}>
            <div className={'mt-4'}>
              <h3><strong>Notifying of Copyright Infringement</strong></h3>
            </div>
            <div>
              <Badge bg={'light'} text={'secondary'}>Last Updated: February 15, 2023</Badge>
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-4'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>Overview</strong></h6>
            <div>
              The notification process outlined here is consistent with the process suggested by the Digital Millennium
              Copyright Act (the text of which can be found at the United States Copyright Office’s website, located
              online at www.copyright.gov). Here is a summary of the process:
            </div>
            <div className={'mt-3'}>
              <ol>
                <li>
                  If any person or entity believes that material on an Intramark-hosted (for example, intramark.io)
                  website infringes their copyright, that person or entity may send us a written notice, as described below.
                  Intramark will review the request and attempt to remove or disable the allegedly infringing material.
                </li>
                <li className={'pt-2'}>
                  Intramark will attempt to notify the user who posted the allegedly infringing material. That user then
                  has the right to request that the material be re-enabled. If they properly make such a request, Intramark
                  will re-enable the material unless and until the two parties jointly ask us to remove it or a court orders
                  us to remove it.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-4'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>Guidelines</strong></h6>
            <div>
              Only copyright owners can report a suspected infringement violation to Intramark. If you are not the
              copyright owner (or the authorized representative of the owner), then you cannot report a suspected
              infringement to Intramark. If you believe that any content on an Intramark-hosted website infringes
              another party&#39;s copyright, you should advise the copyright owner directly. Please note that you may be
              liable for damages, including court costs and attorneys’ fees, if you materially misrepresent that content
              on a website is copyright-infringing. When in doubt, you should consult an attorney. In any case, if you
              believe your copyright is being infringed, we strongly urge you to contact the allegedly-infringing user
              directly.
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-4'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>Submitting Your Notice to Intramark</strong></h6>
            <div>
              We need your help finding the infringing content and we need to be sure that we are doing the right
              thing by removing the content. Therefore, we require the following details in your notice to Intramark:
            </div>
            <div className={'mt-3'}>
              <ol>
                <li>
                  Your name, mailing address, telephone number and email address;
                </li>
                <li className={'pt-2'}>
                  Sufficient detail about the copyrighted work;
                </li>
                <li className={'pt-2'}>
                  The URL or other specific location on our website(s) that contains the material that you claim infringes
                  your copyright;
                </li>
                <li className={'pt-2'}>
                  A statement by you that you have a good faith belief that the disputed use is not authorized by the
                  copyright owner, its agent, or the law (e.g., “I have a good faith belief that use of the copyrighted
                  materials described above as allegedly infringing is not authorized by the copyright owner, its agent, or
                  the law.”);
                </li>
                <li className={'pt-2'}>
                  A statement by you that the information contained in your notice is accurate and that you attest
                  under the penalty of perjury that you are the copyright owner or that you are authorized to act on the
                  copyright owner's behalf (e.g., “I swear, under penalty of perjury, that the information in the notification
                  is accurate and that I am the copyright owner or am authorized to act on behalf of the owner of an
                  exclusive right that is allegedly infringed.”);
                </li>
                <li className={'pt-2'}>
                  An electronic or physical signature of the owner of the copyright or a person authorized to act on the
                  owner's behalf.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-4'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>Where to Send Your Notice</strong></h6>
            <div>
              Your notice can be sent to our copyright agent at:
            </div>
            <div className={'mt-3'}>
              Intramark Inc.
            </div>
            <div>
              1 Dry Hill Ct
            </div>
            <div>
              Norwalk, CT 06851, USA
            </div>
            <div className={'mt-3'}>
              <a href={'mailto:copyright@intramark.io'}>copyright@intramark.io</a>
            </div>
            <div className={'mt-3'}>
              Attn: Legal Department, DMCA Complaint
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-4'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>How will Intramark Respond</strong></h6>
            <div>
              After we receive a proper written notice, we will remove or disable the allegedly infringing content,
              regardless of the nature of the copyright (e.g., a user post, a webpage, or an application). We will
              document those alleged infringements on which we act. Also, we will notify the user and, if requested,
              provide the report to the user. Please note that in addition to being forwarded to the user who provided
              the allegedly infringing content, a copy of the legal notice you submit to Intramark (with your personal
              information removed) may be sent to a third-party which may publish and/or annotate it. We may
              exclude egregious or repeat infringers from our website(s) and terminate their Intramark services.
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-4'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>Restoration of the Removed Content</strong></h6>
            <div>
              If a user of our websites believes that their content was removed or disabled by mistake or
              misidentification, the user may send us a written counter-notification, which should include the
              following:
            </div>
            <div className={'mt-3'}>
              <ol>
                <li>
                  The user’s name, mailing address, telephone number and email address;
                </li>
                <li className={'pt-2'}>
                  Identification of the material that has been removed or disabled and the URL or other specific
                  location on our website(s) at which the material appeared before it was removed or disabled;
                </li>
                <li className={'pt-2'}>
                  A statement that the user consents to the jurisdiction of the Federal District Court in which the user’s
                  address is located, or San Francisco, California if the user’s address is outside the United States;
                </li>
                <li className={'pt-2'}>
                  A statement under penalty of perjury that the user has a good faith belief that the material in
                  question was removed or disabled as a result of mistake or misidentification of the material to be
                  removed or disabled (e.g., “I swear, under penalty of perjury, that I have a good faith belief that each
                  search result, message, or other item of content identified above was removed or disabled as a result of
                  a mistake or misidentification of the material to be removed or disabled, or that the material identified
                  by the complainant has been removed or disabled at the URL identified and will no longer be shown.”);
                </li>
                <li className={'pt-2'}>
                  The user’s physical or electronic signature.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-4'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>Process for Restoration of Content</strong></h6>
            <div>
              We will restore the removed or disabled content following 10 business days from the date that we
              receive a proper written counter-notification, unless our copyright agent first receives notice that a
              court action has been filed to restrain the user from engaging in infringing activity related to the
              removed or disabled content.
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}