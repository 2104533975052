import React from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { Header } from '../../components/Header/Header';

export const TermsScreen: React.FC = () => {
  return (
    <section>
      <Header />
      <Container className={'pt-4 pb-5'}>
        <Row className={'justify-content-center'}>
          <Col sm={6} className={'text-start'}>
            <div className={'mt-3'}>
              <h3><strong>Terms of Service</strong></h3>
            </div>
            <div>
              <Badge bg={'light'} text={'secondary'}>Last Updated: February 15, 2023</Badge>
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-4'}>
          <Col sm={6} className={'text-justify'}>
            <div>
              Please read these Website Terms of Service (“Terms”) carefully before using any website and any
              services made available via such website(s) (collectively, the “Site”) owned or operated by Intramark Inc.
              and or its affiliate(s) (collectively, “Intramark”). These Terms govern your use of the Site that links to
              these Terms. By accessing, using, downloading, or logging into the Site, you agree to these Terms. If at
              any time you do not agree with any of these Terms, you must immediately stop using the Site. These
              Terms are subject to change by Intramark, in its sole discretion, at any time, without prior notice. Any
              changes to these Terms will be in effect as of the “Last Updated Date” referenced in these Terms. By
              accessing, using, downloading, or logging into the Site after the “Last Updated Date”, you agree to the
              changes to these Terms.
            </div>
            <div className={'mt-3'}>
              The terms and conditions for organizations&#39; use of Intramark&#39;s products and services, and for
              professional services, support services, training services, and free trials, are separate from these Terms
              and are available at <a href={'/agreements'}>https://www.intramark.io/agreements.</a>
            </div>
            <div className={'mt-3'}>
              Please carefully review our <a href={'/privacy-policy'}>Privacy Policy</a> as it also governs your use of the Site and certain of our
              activities and is incorporated by reference into this Terms.
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-5'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>I. Intellectual Property</strong></h6>
            <div>
              Except as may otherwise be provided, the Site and the content included in or made available through
              the Site, such as text, graphics, logos, images, sounds, videos, digital downloads, data compilation,
              software, and documents, are the exclusive property of Intramark, its licensors and content providers
              and are protected by United States and international intellectual property laws. All rights, title and
              interest not expressly granted with respect to the Site and content provided on or made available
              through the Site are reserved and retained by Intramark, its licensors and content providers.
            </div>
            <div className={'mt-3'}>
              The Intramark graphics, logos, designs, page headers, button icons, scripts, trademarks, and service
              names are the trademarks or trade dress owned by Intramark and may not be used in any manner
              without the prior written permission of Intramark. All other names, logos, product and service names,
              designs and slogans that may appear on the Site are the property of their respective owners, who may
              or may not be affiliated with, connected to, or sponsored by Intramark.
            </div>
            <div className={'mt-3'}>
              Certain content and materials made available through the Site and our services are protected by
              copyright law. Aside from Submissions and other third-party materials, all materials and other
              information on our Site, including but not limited to, all text, graphics, logos, icons, images, audio and
              video clips, downloads, data compilations, and software are the exclusive property of Intramark and/or
              its licensors and are protected by all United States and international copyright laws.
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-5'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>II. Access and Use of the Site</strong></h6>
            <div>
              When you access or use the Site, you agree to use the Site only for purposes that are permitted by these
              Terms and any applicable law, regulation or generally accepted practices or guidelines in the relevant
              jurisdictions. In addition, you agree that you are solely responsible for any breach of your obligations
              under these Terms and for the consequences of any such breach. As a condition of your use of the Site,
              you warrant to Intramark that you will not use the Site for any purpose that is unlawful or otherwise
              prohibited by these Terms. Whether on behalf of yourself or on behalf of any third party, you agree not
              to:
            </div>
            <div className={'mt-3 d-flex'}>
              <ul>
                <li>
                  Access or attempt to access or use the Site other than through the interface that is provided by
                  Intramark, or through user accounts of others, unless you have specifically been permitted to do so
                  in a separate written agreement signed by an authorized representative of Intramark;
                </li>
                <li className={'pt-2'}>
                  Access or attempt to access or use the Site, or any portion thereof, through any automated means,
                  including but not limited to the use of scripts or web crawlers;
                </li>
                <li className={'pt-2'}>
                  Engage in any activity that disrupts or otherwise interferes with the Site (or the servers and
                  networks which are connected to the Site), or the use of the same by others, as determined in
                  Intramark’s sole discretion;
                </li>
                <li className={'pt-2'}>
                  Scrape, duplicate, reproduce, copy, republish, license, sell, trade or resell the Site or any of its
                  content for any purpose, unless you have specifically been permitted to do so in a separate written
                  agreement signed by an authorized representative of Intramark;
                </li>
                <li className={'pt-2'}>
                  Divert or attempt to divert Intramark customers to another website, mobile application, or service;
                </li>
                <li className={'pt-2'}>
                  Sell, share, or otherwise transfer your account username, password, other information, or your
                  rights or obligations under these Terms;
                </li>
                <li className={'pt-2'}>
                  Send unsolicited or unauthorized emails;
                </li>
                <li className={'pt-2'}>
                  Use the Site to store or transmit infringing, libelous, or otherwise unlawful or tortious material, or to
                  store or transmit material in violation of third party rights, including but not limited to privacy rights;
                </li>
                <li className={'pt-2'}>
                  Use the Site to store or transmit malicious code or malware, or to engage in phishing or other
                  fraudulent activity;
                </li>
                <li className={'pt-2'}>
                  Restrict or inhibit any other person from using the Site;
                </li>
                <li className={'pt-2'}>
                  Modify, adapt, translate, reverse engineer, probe, scan, test, decompile, or disassemble any portion
                  of the Site except and to the extent required by third party licensing terms governing use of certain
                  open source components that may be included;
                </li>
                <li className={'pt-2'}>
                  Attempt to do anything, or encourage, assist, or allow any third party to do anything in violation of
                  these Terms; or
                </li>
                <li className={'pt-2'}>
                  Use a false email address, impersonate any person or entity, or falsely state or imply or otherwise
                  misrepresent your affiliation with any person or entity in connection with the Site.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-5'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>III. Accounts</strong></h6>
            <div>
              It is your responsibility to ensure your computer system or device meets all the necessary technical
              specifications to enable you to access and use the Site and is compatible with the Site. To access certain
              services, features, portions, or content on the Site, you may be required to create an account and
              specify a password. To create an account, you must be at least 18 years old and you must provide
              current, complete, truthful, and accurate information as prompted by the applicable registration form. If
              your information changes at any time, you agree to update your account to reflect those changes.
            </div>
            <div className={'mt-3'}>
              If your Intramark account is assigned and/or managed by your employer or a third party, then additional
              terms may apply to your use of the Site and your employer or the third party may be able to access or
              disable your account with or without Intramark’s involvement and may have information relating to
              your access and use of the account shared with them.
            </div>
            <div className={'mt-3'}>
              If you create an account or an account is created for you, you may not share your account with anyone
              else and you agree to treat your account information as confidential and must not reveal the
              information to anyone. You are responsible for maintaining the confidentiality of and preventing the
              unauthorized use of your account information and for all activities that occur under your account. You
              must immediately notify Intramark of any confidentiality breach or unauthorized use of your account or
              your account information. Intramark reserves the right to suspend, disable, deactivate, or block your
              account, at any time, for any lawful reason.
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-5'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>IV. Submissions</strong></h6>
            <div>
              You and other users of the Site may have the ability to post, upload, input, submit or otherwise make
              available (“Posting”) certain information, content, or materials, including testimonials, reviews, posts,
              comments and other user-generated content, pictures and documents through the Site (each a
              “Submission(s)”). A Submission reflects only the opinions or reviews of the user who made the
              Submission available and does not reflect the opinions of Intramark, regardless of whether or not the
              user is affiliated with Intramark. Submissions are made available for your general knowledge only and
              should never be relied upon. By Posting your Submission(s), you grant to Intramark, to the maximum
              extent permitted under applicable law, an irrevocable, perpetual, sublicensable, transferable,
              nonexclusive, and royalty-free, and worldwide license, without additional consideration to you or any
              third party, to copy, reproduce, distribute, transmit, perform and display (publicly or otherwise), create
              derivative works of, adapt, modify, edit, translate, reformat, and otherwise use, analyze and exploit such
              Submission, in any medium or form now existing or later developed, and for any purpose; to publish (or
              not publish) your name in connection with your Submission(s); and the right to sublicense such rights to
              others, including Intramark’s affiliates and any suppliers of the Site and/or our services.
            </div>
            <div className={'mt-3'}>
              By Posting a Submission, you represent and warrant: that you own, control, or have otherwise secured,
              all of the rights to your Submission, including the content therein, and the Posting thereof; that the
              content is complete and accurate and is not fraudulent, tortious or otherwise in violation of any
              applicable law or any right of any third party; to the extent that the content contains any intellectual
              property, including, without limitation, trademarks, patents, rights of publicity, copyrights to any
              creative works, including, without limitation, text, audio, video, software code, artwork, images,
              photographs, pictures or any other content in whole or in part that is protected by any intellectual
              property laws (collectively, “Submitted IP”), that you are the copyright owner of such Submitted IP, or
              that the copyright owner of such Submitted IP has granted you permission to use such Image or any
              content and/or images contained in such Submitted IP consistent with the manner and purpose of your
              use and as otherwise permitted by these Terms; that you have the rights necessary to grant the licenses
              and sublicenses described in these Terms; that use of the Submission(s) you supply does not violate this
              policy, does not infringe upon the intellectual property rights of any person or entity, and will not cause
              injury to any person or entity; and that you will indemnify Intramark for all claims resulting from your
              Submission(s).
            </div>
            <div className={'mt-3'}>
              Intramark has the right, but not the obligation, in its sole discretion, at any time, to monitor, evaluate,
              analyze, edit, move, alter, or remove Submissions. Intramark takes no responsibility and assumes no
              liability for any Submissions by you or any third party. You are solely responsible for any Submission(s)
              you post.
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-5'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>V. Feedback</strong></h6>
            <div>
              If you provide or otherwise make available to Intramark any suggestions, enhancement requests,
              recommendations, corrections, or other feedback (“Feedback”), whether related to the Site or
              otherwise, then these Terms and Additional Terms applicable to Submissions will also apply to such
              Feedback, and you hereby acknowledge and agree that such Feedback is not confidential information
              and that your provision of such Feedback is gratuitous, unsolicited and without restriction, and does not
              place Intramark under any fiduciary or other obligation. For Feedback not governed by these Terms or
              Additional Terms, you hereby grant to Intramark an irrevocable, perpetual, sublicensable, transferable,
              non-exclusive, and royalty-free, and worldwide license, to use or incorporate into its products and
              services any Feedback. Intramark shall have no obligation to use Feedback, and you shall have no
              obligation to provide Feedback.
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-5'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>VI. External Links</strong></h6>
            <div>
              The Site may include links to third party sites; such linked sites are not under Intramark’s control and
              Intramark is not responsible for the contents of any linked sites or any links contained in any linked sites,
              or any changes or updates to such sites. Intramark is providing these links to you only as a convenience,
              and the inclusion of any link does not imply endorsement of, or affiliation with, any third party site by
              Intramark. Intramark reserves the right to remove any links at any time. YOUR USE OF THIRD PARTY
              SITES IS AT YOUR OWN RISK AND IS SUBJECT TO ANY TERMS, CONDITIONS, AND POLICIES APPLICABLE
              TO SUCH THIRD PARTY SITES.
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-5'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>VII. Notices of Copyright and Other Intellectual Property Infringement</strong></h6>
            <div>
              If you believe that material on the Site infringes your intellectual property rights, you may request the
              removal of the material by following the instructions located here: <a href={'/copyright'}>https://www.intramark.io/copyright</a>
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-5'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>VIII. Disclaimer</strong></h6>
            <div>
              TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE SITE AND ALL INFORMATION, CONTENT,
              MATERIALS, PROGRAMS, OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE THROUGH THE
              SITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, UNLESS OTHERWISE SPECIFIED IN THESE
              TERMS, ADDITIONAL TERMS, AND/OR ANY AGREEMENT. INTRAMARK MAKES NO REPRESENTATIONS OR
              WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR
              THE INFORMATION, CONTENT, MATERIALS, PROGRAMS, OR SERVICES INCLUDED ON OR OTHERWISE
              MADE AVAILABLE TO YOU THROUGH THE SITE, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU
              EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. TO THE FULL EXTENT PERMITTED
              BY APPLICABLE LAW, INTRAMARK EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
              EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT WITH RESPECT TO
              THE SITE AND ALL INFORMATION, CONTENT, MATERIALS, PROGRAMS, OR SERVICES INCLUDED ON OR
              OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SITE.
            </div>
            <div className={'mt-3'}>
              Your use and access to the Site may be subject to limitations, delays, failure, and other problems
              inherent in the use of the Internet and electronic communications. Intramark is not responsible for any
              delays, failures or other damage resulting from such problems.
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-5'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>IX. Limitation of Liability</strong></h6>
            <div>
              IN NO EVENT SHALL INTRAMARK BE LIABLE FOR ANY LOSSES OR DAMAGES WHATSOEVER, INCLUDING
              BUT NOT LIMITED TO DIRECT, INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL
              DAMAGES, OR DAMAGES BASED ON LOST PROFITS, DATA OR USE, HOWEVER CAUSED AND, WHETHER
              IN AN ACTION IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY (INCLUDING
              NEGLIGENCE), ARISING FROM YOUR ACCESS TO, OR USE OF, THE SITE OR ANY CONTENT, MATERIALS, OR
              INFORMATION MADE AVAILABLE ON OR THROUGH THE SITE, WHETHER OR NOT YOU HAVE BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-5'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>X. Indemnification</strong></h6>
            <div>
              To the fullest extent permitted by law, you agree to indemnify, release, and hold harmless Intramark
              and its directors, officers, employees, and contractors for any third party claims, demands, suits,
              proceedings, liabilities, losses, damages, judgements, fines, or costs and expenses (including, without
              limitation, reasonable attorneys’ fees) arising out of or relating to: (a) your violation or alleged violation
              of these Terms; or (b) your Submissions.
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-5'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>XI. Applicable Law</strong></h6>
            <div>
              These Terms and any dispute arising out of or related to these Terms shall be governed by the laws of
              the State of California, without regard to its conflict of laws rules.
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-5'}>
          <Col sm={6} className={'text-justify'}>
            <h6><strong>XIII. Modification, Termination, and Severability</strong></h6>
            <div>
              Intramark reserves the right to modify the Site, including adding, amending, suspending, or removing
              functionality, features, material, and content on the Site, and these Terms at any time, without notice.
              All provisions of these Terms which by their nature should survive termination shall survive the
              termination of your use and access to the Site, including, without limitation, provisions regarding
              ownership, warranty disclaimers, indemnity, and limitations of liability. If any of these conditions shall
              be deemed invalid, void, or for any reason unenforceable, such condition(s) shall be deemed severable
              and shall not affect the validity and enforceability of any remaining condition. Intramark may terminate
              your privilege to use or access the Site immediately and without notice for any reason whatsoever.
              Upon such termination, you agree to immediately cease accessing or using the Site and agree not to
              access or make use of, or attempt to use, the Site. Furthermore, you acknowledge that Intramark
              reserves the right to take action – technical, legal, or otherwise – to block, nullify, or deny your ability to
              access the Site. You understand and agree that Intramark may exercise this right in its sole discretion,
              and this right shall be in addition to, and not in substitution for, any other rights and remedies available
              at law or in equity to Intramark.
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}