import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Carousel, Col, Container, Fade, Image, Row } from 'react-bootstrap';
import { Header } from '../../components/Header/Header';
import './PeopleServiceScreen.scss';

import peopleService from '../../assets/team_work.png';
import { Subscribe } from '../../components/Subscribe/Subscribe';
import {
  lightbulbOn,
  listUl,
  vennDiagram,
  handshakeAngle,
  browser,
  clipboardQuestion,
  fileLock, handshake, filePieChart, towerBroadcast, peopleGroup, handHoldingDollar,
} from '../../utils/images';

export const PeopleServiceScreen: React.FC = () => {

  const [isOutboundSelected, toggleOutboundSelected] = useState(true);
  const [isInboundSelected, toggleInboundSelected] = useState(false);

  return (
    <section>
      <Header/>
      <section>
        <Container className={ 'main-container' } fluid={ 'sm' }>
          <Row className={ 'align-items-center' }>
            <Col sm={ 5 } xs={12} className={ 'justify-content-center mb-md-0 mb-5' }>
              <Fade in>
                <Carousel
                  fade
                  interval={ 3000 }
                  indicators={ false }
                >
                  <Carousel.Item>
                    <Image
                      className="d-block w-100"
                      src={ peopleService }
                      alt="First slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </Fade>
            </Col>
            <Col sm={ 1 }/>
            <Col sm={ 6 } xs={12} className={ 'text-start' }>
              <div className={ 'fade-in' }>
                <h2>People focused follow-through</h2>
                <div className={ 'mt-4' }>
                  Our People Services<span className={ 'font-primary' }>^</span> team share insights with employees
                  and leadership to align on initiatives to enhance
                  the employee experience. Organizations also leverage our People
                  Services<span className={ 'font-primary' }>^</span> team to execute on employee
                  experience initiatives in a timely manner.
                </div>
                <Row className={ 'mt-4' }>
                  <Col xs={ 2 } className={ 'd-flex justify-content-center align-items-center icon-border-right' }>
                    <Image
                      src={ lightbulbOn }
                      width={ 44 }
                    />
                  </Col>
                  <Col xs={ 10 }>
                    Generate insights from real-time feedback raw data
                  </Col>
                </Row>
                <Row className={ 'mt-4' }>
                  <Col xs={ 2 } className={ 'd-flex justify-content-center align-items-center icon-border-right' }>
                    <Image
                      src={ vennDiagram }
                      width={ 40 }
                    />
                  </Col>
                  <Col xs={ 10 }>
                    Surface thematic opportunities and systemic risks, including DEI & Belonging matters that may
                    disproportionately impact diverse populations
                  </Col>
                </Row>
                <Row className={ 'mt-4' }>
                  <Col xs={ 2 } className={ 'd-flex justify-content-center align-items-center icon-border-right' }>
                    <Image
                      src={ listUl }
                      width={ 40 }
                    />
                  </Col>
                  <Col xs={ 10 }>
                    Recommend holistic people culture initiatives to define, monitor, and continuously enhance the
                    employee experience
                  </Col>
                </Row>
                <Row className={ 'mt-4' }>
                  <Col xs={ 2 } className={ 'd-flex justify-content-center align-items-center icon-border-right' }>
                    <Image
                      src={ handshakeAngle }
                      width={ 44 }
                    />
                  </Col>
                  <Col xs={ 10 }>
                    Provide added capacity to take timely, holistic action on recommendations
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={ 'bg-light' }>
        <Container className={ 'main-container' }>
          <Row className={ 'text-start' }>
            <Col sm={ 12 }>
              <h2>Explore People Services<span className={ 'font-primary' }>^</span></h2>
            </Col>
            <Col sm={ 12 } xs={ 12 } className={ 'mt-4' }>
              <ButtonGroup className={ 'w-100' }>
                <Button
                  variant={ isOutboundSelected ? 'primary' : 'outline-primary' }
                  className={ 'btn-block' }
                  onClick={ () => toggleOutboundSelected(!isOutboundSelected) }
                >
                  { isOutboundSelected && (
                    <FontAwesomeIcon icon={ faCheck } className={ 'me-2' }/>
                  ) }
                  Outbound
                </Button>
                <Button
                  variant={ isInboundSelected ? 'primary' : 'outline-primary' }
                  className={ 'btn-block' }
                  onClick={ () => toggleInboundSelected(!isInboundSelected) }
                >
                  { isInboundSelected && (
                    <FontAwesomeIcon icon={ faCheck } className={ 'me-2' }/>
                  ) }
                  Inbound
                </Button>
              </ButtonGroup>
            </Col>
            { isOutboundSelected && !isInboundSelected ? (
              <Col sm={ 12 } className={ 'mt-5' }>
                <Row className={ 'text-start' }>
                  <Col sm={ 4 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
                    <Card className={'h-100'}>
                      <Card.Title className={ 'text-center p-2 pt-5' }>
                        <Image
                          src={ clipboardQuestion }
                          width={ 44 }
                        />
                      </Card.Title>
                      <Card.Body>
                        <div className={ 'text-center' }>
                          <h5><strong> Perform deep dives </strong></h5>
                          <span>
                           Conduct employee interviews and workshops on a confidential, impartial, informal,
                           off-the-record basis to dive deeper into employee experience themes and opportunities
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={ 4 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
                    <Card className={'h-100'}>
                      <Card.Title className={ 'text-center p-2 pt-5' }>
                        <Image
                          src={ filePieChart }
                          width={ 40 }
                        />
                      </Card.Title>
                      <Card.Body>
                        <div className={ 'text-center' }>
                          <h5><strong>Create insight reports</strong></h5>
                          <span>
                            Generate insights from real-time feedback data, off-the-record conversations,
                            and workshops; recommend initiatives to enhance the employee experience
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={ 4 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
                    <Card className={ 'h-100' }>
                      <Card.Title className={ 'text-center p-2 pt-5' }>
                        <Image
                          src={ towerBroadcast }
                          width={ 46 }
                        />
                      </Card.Title>
                      <Card.Body>
                        <div className={ 'text-center' }>
                          <h5><strong>Facilitate communication strategy</strong></h5>
                          <span>
                            Help leadership communicate employee experience insights and action plan for key recommendations
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className={ 'text-start' }>
                  <Col sm={2} />
                  <Col sm={ 4 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
                    <Card className={'h-100'}>
                      <Card.Title className={ 'text-center p-2 pt-5' }>
                        <Image
                          src={ peopleGroup }
                          width={ 50 }
                        />
                      </Card.Title>
                      <Card.Body>
                        <div className={ 'text-center' }>
                          <h5><strong> Execute on recommendations  </strong></h5>
                          <span>
                            Provide People Services<span className={'font-primary'}>^</span> team resources to execute on recommendations
                            and on a holistic roadmap to define, monitor, and continuously enhance the employee experience
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={ 4 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
                    <Card className={'h-100'}>
                      <Card.Title className={ 'text-center p-2 pt-5' }>
                        <Image
                          src={ handHoldingDollar }
                          width={ 44 }
                        />
                      </Card.Title>
                      <Card.Body>
                        <div className={ 'text-center' }>
                          <h5><strong>Measure ROI</strong></h5>
                          <span>
                            Measure impact of employee experience initiatives and EX Advisor services to
                            leadership (e.g., reduced turnover, increased productivity)
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col sm={ 12 } className={ 'mt-5' }>
                <Row className={ 'text-start' }>
                  <Col sm={ 3 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
                    <Card className={'h-100'}>
                      <Card.Title className={ 'text-center p-2 pt-5' }>
                        <Image
                          src={ clipboardQuestion }
                          width={ 44 }
                        />
                      </Card.Title>
                      <Card.Body>
                        <div className={ 'text-center' }>
                          <h5><strong> Perform deep dives </strong></h5>
                          <span>
                           Conduct employee interviews and workshops on a confidential, impartial, informal,
                           off-the-record basis to dive deeper into employee experience themes and opportunities
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={ 3 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
                    <Card className={'h-100'}>
                      <Card.Title className={ 'text-center p-2 pt-5' }>
                        <Image
                          src={ fileLock }
                          width={ 44 }
                        />
                      </Card.Title>
                      <Card.Body>
                        <div className={ 'text-center' }>
                          <h5><strong>Provide Ombuds services </strong></h5>
                          <span>
                            Offer employees means to surface workplace issues confidentially, and strategize resolution options
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={ 3 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
                    <Card className={ 'h-100' }>
                      <Card.Title className={ 'text-center p-2 pt-5' }>
                        <Image
                          src={ handshake }
                          width={ 50 }
                        />
                      </Card.Title>
                      <Card.Body>
                        <div className={ 'text-center mt-2' }>
                          <h5><strong> Provide conflict resolution </strong></h5>
                          <span>
                            Guide or coach employees on how to deal directly with other parties; facilitate informal
                            conflict resolution process between employees; assist employee in surfacing issues to
                            formal resolution channels
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={ 3 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
                    <Card className={'h-100'}>
                      <Card.Title className={ 'text-center p-2 pt-5' }>
                        <Image
                          src={ filePieChart }
                          width={ 40 }
                        />
                      </Card.Title>
                      <Card.Body>
                        <div className={ 'text-center' }>
                          <h5><strong>Create insight reports</strong></h5>
                          <span>
                            Generate insights from real-time feedback data, off-the-record conversations,
                            and workshops; recommend initiatives to enhance the employee experience
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className={ 'text-start' }>
                  <Col sm={ 4 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
                    <Card className={ 'h-100' }>
                      <Card.Title className={ 'text-center p-2 pt-5' }>
                        <Image
                          src={ towerBroadcast }
                          width={ 46 }
                        />
                      </Card.Title>
                      <Card.Body>
                        <div className={ 'text-center' }>
                          <h5><strong>Facilitate communication strategy</strong></h5>
                          <span>
                            Help leadership communicate employee experience insights and action plan for key recommendations
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={ 4 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
                    <Card className={'h-100'}>
                      <Card.Title className={ 'text-center p-2 pt-5' }>
                        <Image
                          src={ peopleGroup }
                          width={ 50 }
                        />
                      </Card.Title>
                      <Card.Body>
                        <div className={ 'text-center' }>
                          <h5><strong> Execute on recommendations  </strong></h5>
                          <span>
                            Provide People Services<span className={'font-primary'}>^</span> team resources to execute on recommendations
                            and on a holistic roadmap to define, monitor, and continuously enhance the employee experience
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={ 4 } className={ 'ps-3 pe-3 pb-3 wrapper' }>
                    <Card className={'h-100'}>
                      <Card.Title className={ 'text-center p-2 pt-5' }>
                        <Image
                          src={ handHoldingDollar }
                          width={ 44 }
                        />
                      </Card.Title>
                      <Card.Body>
                        <div className={ 'text-center' }>
                          <h5><strong>Measure ROI</strong></h5>
                          <span>
                            Measure impact of employee experience initiatives and EX Advisor services to
                            leadership (e.g., reduced turnover, increased productivity)
                          </span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      </section>
      <section>
        <Subscribe/>
      </section>
    </section>
  )
}