import React from 'react';
import { Badge, Col, Container, Row, Table } from 'react-bootstrap';
import { Header } from '../../components/Header/Header';

export const DataProcessingAgreementScreen: React.FC = () => {
  return (
    <section>
      <Header/>
      <Container className={ 'pt-4 pb-5' }>
        <Row className={ 'justify-content-center' }>
          <Col sm={ 6 } className={ 'text-start' }>
            <div className={ 'mt-4' }>
              <h3><strong>Data Processing Agreement</strong></h3>
            </div>
            <div>
              <Badge bg={ 'light' } text={ 'secondary' }>Last Updated: February 15, 2023</Badge>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <div>
              THIS DATA PROCESSING AGREEMENT (“<strong>DPA</strong>”) BETWEEN THE INTRAMARK LEGAL ENTITY SIGNING AN
              ORDER FORM AND ITS AFFILIATES (COLLECTIVELY, “<strong>INTRAMARK</strong>”, “<strong>COMPANY</strong>”,
              “<strong>WE</strong>”, “<strong>US</strong>” or “<strong>PROCESSOR</strong>”) AND THE INDIVIDUAL
              OR LEGAL ENTITY LICENSING THE SERVICES UNDER AN APPLICABLE
              ORDER FORM AND/OR INTRAMARK’S MASTER SAAS AGREEMENT (“<strong>THE PRINCIPAL AGREEMENT</strong>”)
              (“<strong>CUSTOMER</strong>”, “<strong>YOU</strong>” OR “<strong>CONTROLLER</strong>”) AND TOGETHER
              WITH INTRAMARK, THE “<strong>PARTIES</strong>” GOVERNS CUSTOMER’S ACCESS AND USE OF THE SERVICES.
            </div>
            <div className={ 'mt-3' }>
              BY ACCEPTING THIS DPA WHILE EXECUTING AN ORDER FORM AND/OR PRINCIPAL AGREEMENT THAT
              REFERENCES THIS DPA, CUSTOMER AGREES TO THE TERMS OF THIS DPA. IF YOU ARE ENTERING INTO
              THIS DPA ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE
              AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH
              CASE THE TERMS “CUSTOMER” “YOU” OR “YOUR” SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF
              YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS,
              YOU MUST NOT ACCEPT THIS DPA AND SHALL NOT BE PERMITTED TO USE THE SERVICES.
            </div>
            <div className={ 'mt-3' }>
              BY ACCEPTING THE TERMS OF THIS DPA YOU REPRESENT AND WARRANT THAT ANY AND ALL
              INFORMATION PROVIDED TO US THROUGH THE SERVICE IS TRUE, ACCURATE AND COMPLETE. THE
              PROVISION OF FALSE OR FRAUDULENT INFORMATION IS STRICTLY PROHIBITED.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>Background and undertakings:</strong></h6>
            <div className={ 'text-justify' }>
              <ol type={ 'a' }>
                <li>
                  Controller and Intramark have entered into the Principal Agreement under which Intramark agreed to
                  provide certain SaaS and professional services (the “Service”) pursuant to the Principal Agreement to
                  the Controller and/or its Affiliates. In rendering the Service, Intramark may from time to time be
                  provided with, or have access to, information of the Controller which may qualify as Personal Data (as
                  defined below) which is subject to Applicable Data Protection Legislation.
                </li>
                <li className={ 'pt-2' }>
                  Subject to the terms of this DPA, Intramark shall process Controller‘s data as a processor for the
                  provision of the Service under the Principal Agreement and as further described in Annex 1.
                </li>
                <li className={ 'pt-2' }>
                  The Parties agree that the terms and conditions set out below, are an addendum to the Principal
                  Agreement.
                </li>
              </ol>
              <div className={ 'mt-3' }>
                <strong>Now therefore</strong>, and in order to enable the Parties to comply with the Applicable Data
                Protection
                Legislation (as defined below), the Parties have entered into this DPA as follows:
              </div>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>1. Definitions</strong></h6>
            <div>
              In this DPA the following terms have the following meanings, terms not otherwise defined herein shall
              have the same meaning as in the Principal Agreement:
            </div>
            <div className={ 'mt-3' }>
              “<strong>Affiliate/s</strong>” means any legal entity directly or indirectly controlling, controlled by or
              under common
              control with a party to the Principal Agreement, where “control” means the ownership of a majority
              share of the voting stock, equity, or voting interests of such entity;
            </div>
            <div className={ 'mt-3' }>
              “<strong>Applicable Data Protection Legislation</strong>” means all applicable laws and regulations,
              subject to the processing of Controller Data under this DPA, including without limitation (as applicable),
              (i) the General Data Protection Regulation (EU) 2016/679 (the “<strong>GDPR</strong>”); (ii) the UK Data
              Protection Act 2018 (“<strong>UK DPA</strong>”) and the UK General Data Protection Regulation as defined
              by the DPA as amended by the Data Protection, Privacy and Electronic Communications (Amendments etc.) (EU
              Exit) Regulations 2019 (together with the DPA, the “<strong>UK GDPR</strong>”), (ii) the California
              Consumer Privacy Act of 2018, as amended (Cal. Civ. Code §§ 1798.100 to 1798.199), the CCPA Regulations
              (Cal. Code Regs. tit. 11, §§ 999.300 to 999.337), and any related regulations or guidance provided by the
              California Attorney General (together, the “<strong>CCPA</strong>”);
            </div>
            <div className={ 'mt-3' }>
              “<strong>Controller Data</strong>” means any Personal Data processed by Processor on behalf of Controller,
              pursuant to or in connection with the Principal Agreement;
            </div>
            <div className={ 'mt-3' }>
              “<strong>Controller to Processor Clauses</strong>” means (i) in respect of transfers of Personal Data
              subject to the GDPR, Module 2 (Controller to Processor) of the standard contractual clauses for
              the transfer of Personal Data to third countries set out in Commission Decision 2021/914 of 4 June 2021;
              and (ii) in respect of transfers of Personal Data subject to the UK GDPR, the International Data Transfer
              Addendum to the EU Commission Standard Contractual Clauses (version B.1.0) issued by the UK Information
              Commissioner, in each case as amended, updated or replaced from time to time;
            </div>
            <div className={ 'mt-3' }>
              “<strong>Data Processing Agreement or DPA</strong>” means this DPA and all appendices attached hereto (as
              amended from time to time in accordance herewith);
            </div>
            <div className={ 'mt-3' }>
              “<strong>Personal Data</strong>” means any information relating to an identified or identifiable natural
              person (‘data subject’); an identifiable natural person is one who can be identified, directly or
              indirectly, in particular by reference to an identifier such as a name, an identification number, location
              data, an online identifier or as otherwise referred to as “personal information”, “personally identifiable
              information” or similar term defined in the Applicable Data Protection Legislation;
            </div>
            <div className={ 'mt-3' }>
              “<strong>Personal Data Breach</strong>” means a breach of security leading to the accidental or unlawful
              destruction, loss, alteration, unauthorized disclosure of, or access to Controller Data processed,
              transmitted, stored or otherwise processed;
            </div>
            <div className={ 'mt-3' }>
              “<strong>Processor to Processor Clauses</strong>” means, as relevant, (i) in respect of transfers of
              Personal Data subject to the GDPR, Module 3 (Processor to Processor) of the standard contractual clauses
              for the transfer of Personal Data to third countries set out in Commission Decision 2021/914 of 4 June
              2021; and (ii) in respect of transfers of Personal Data subject to the UK GDPR, the International Data
              Transfer Addendum to the EU Commission Standard Contractual Clauses (version B.1.0) issued by the UK
              Information Commissioner, in each case as in force and as amended, updated or replaced from time to time;
            </div>
            <div className={ 'mt-3' }>
              “<strong>Sub-Processor/s</strong>” means a Processor engaged by Intramark to carry out Processing in
              respect of Controller Data on behalf of the Controller;
            </div>
            <div className={ 'mt-3' }>
              “<strong>Third-Country</strong>” means (i) in relation to Personal Data transfers subject to the GDPR, any
              country outside of the scope of the data protection laws of the European Economic Area, excluding
              countries approved as providing adequate protection for Personal Data by the European Commission from time
              to time; and (ii) in relation to Personal Data transfers subject to the UK GDPR, any country outside of
              the scope of the data protection laws of the UK, excluding countries approved as providing adequate
              protection for Personal Data by the relevant competent authority of the UK from time to time.
            </div>
            <div className={ 'mt-3' }>
              The terms recognized by the GDPR, such as “Controller”, “Data Subject”, “Process”, “Processor”
              “Processing”, “Supervisory Authority” shall have the meanings set out therein even if such terms are not
              capitalized in this DPA.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>2. Processing of Controller Data</strong></h6>
            <div className={ 'mt-3' }>
              2.1 Each Party shall comply with the Applicable Data Protection Legislation at all times.
            </div>
            <div className={ 'mt-3' }>
              2.2 The Processor shall solely process the Controller Data to the extent necessary to provide the Service
              to the Controller.
            </div>
            <div className={ 'mt-3' }>
              2.3. The Processor agrees to only process Controller Data, in accordance with Controller’s documented
              instructions under this DPA, the Principal Agreement, the Order Form and in accordance with the
              Applicable Data Protection Legislation.
            </div>
            <div className={ 'mt-3' }>
              2.4 Controller warrants and represents that it is, and will, at all relevant times remain duly and
              effectively authorized to give instructions. Controller shall have sole responsibility for the accuracy,
              quality and legality of Controller Data and how Controller acquired Controller Data. This DPA, the
              instructions, the Principal Agreement and the Order Form are Controller’s complete and final
              instructions to Processor for the Processing of Controller Data. Any additional or alternate instructions
              must be agreed upon separately in writing between authorized representatives of both Parties.
            </div>
            <div className={ 'mt-3' }>
              2.5 The Processor shall immediately notify Controller if the Processor cannot fulfil its obligations under
              this DPA or if the Processor is of the view that an instruction regarding the processing of Controller
              Data
              given by Controller would be in breach of Applicable Data Protection Legislation, unless the Processor is
              prohibited from notifying Controller under applicable Data Protection Legislation.2.6 The Processor shall
              immediately notify Controller in writing if the Supervisory Authority requests access to Controller Data
              which the Processor processes on behalf of Controller, unless prohibited from doing so by the
              Supervisory Authority.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>3. CCPA</strong></h6>
            <div className={ 'mt-3' }>
              3.1. The Parties acknowledge and agree that Intramark may qualify as a Service Provider. This section 3
              applies to the extent that Controller is a Business and Intramark processes Personal Information for a
              valid Business Purpose and for Intramark to perform the Service. To the extent there is a conflict
              between this section and other provisions in this DPA, the requirement in this section shall prevail.
            </div>
            <div className={ 'mt-3' }>
              3.2. Intramark will only collect, use, retain, or disclose Personal Information for the Business Purpose
              for which Controller provides or permits Personal Information access or as otherwise permitted by the
              CCPA, and shall provide the same level of privacy protection as is required by the CCPA. Both Parties will
              comply with all applicable requirements of the CCPA when collecting, using, retaining, or disclosing
              Personal Information. Intramark shall make available upon Controller’s reasonable request information
              necessary for Intramark to demonstrate its compliance with provisions of the CCPA applicable to Service
              Providers and notify Controller if Intramark determines that it can no longer meet its obligations under
              the CCPA. Upon Controller’s reasonable written notice, and subject to providing Intramark with an
              opportunity to remediate reasonable concerns raised in such notice, Controller shall have the right to
              take reasonable and appropriate steps to help ensure that Intramark uses the Personal Information in a
              manner consistent with Controller’s obligations under the CCPA and stop and remediate the
              unauthorized use of the Personal Information.
            </div>
            <div className={ 'mt-3' }>
              3.3. Intramark shall not:
              <div className={ 'mt-2' }>
                3.3.1. Sell or Share the Personal Information.
              </div>
              <div className={ 'mt-2' }>
                3.3.2. Retain, use, or disclose the Personal Information outside of the direct business relationship
                between Intramark and Controller and for any purpose other than for the specific purpose of
                performing the Service or as otherwise permitted by the CCPA.
              </div>
              <div className={ 'mt-2' }>
                3.3.3. Combine the Personal Information received from Controller with any Personal Information that
                may be collected from Intramark’s separate interactions with the individual(s) to whom the Personal
                Information relates or from any other sources.
              </div>
              <div className={ 'mt-2' }>
                3.3.4. Collect any information or data other than specified in Annex 1 Section 3.
              </div>
            </div>
            <div className={ 'mt-3' }>
              3.4. To the extent Intramark receives Deidentified information, Intramark shall (i) take commercially
              reasonable measures to ensure that the information cannot be associated with an identified or
              identifiable individual; (ii) publicly commit to maintain and use the information only in a Deidentified
              fashion; and (iii) not attempt to re-identify the information.
            </div>
            <div className={ 'mt-3' }>
              3.5. Taking into account the nature of the processing, Processor shall assist Controller through (i)
              complying with rights requests made pursuant to the CCPA upon being informed by Controller of the
              request and provided with information necessary to comply with the request; and (ii) entering into this
              DPA.
            </div>
            <div className={ 'mt-3' }>
              3.6. For the purposes of this section 3, “Business”, “Business Purpose”, “Deidentified”, “Personal
              Information”, “Sell”, “Share” and “Service Provider” shall have the meanings set out in the CCPA.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>4. Security Measures</strong></h6>
            <div className={ 'mt-3' }>
              4.1. The Processor shall implement appropriate technical and organizational measures to protect and
              safeguard the Controller Data that is processed, against Personal Data Breaches.
            </div>
            <div className={ 'mt-3' }>
              4.2. Processor will maintain its security controls and audits, pursuant to, amongst others, ISO 27001,
              SOC 2 type II and ISO27799 Security management in health as detailed at: <a
              href={ '/security' }>https://www.intramark.io/security</a> or otherwise made reasonably available by
              the Processor. Processor regularly monitors compliance with these safeguards. Processor will not
              materially decrease the overall security of the Service during the term of the Principal Agreement.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>5. Personnel; Confidentiality</strong></h6>
            <div className={ 'mt-3' }>
              5.1. Processor shall take reasonable steps to ensure the reliability of any employee, agent or contractor
              of the Processor who may have access to the Controller Data (“<strong>Personnel</strong>”), ensuring in
              each case that access is strictly limited to Personnel who need to know/access the relevant Controller
              Data, as strictly necessary for the purposes of the Principal Agreement, and to comply with the Applicable
              Data Protection Laws in the context of such Personnel’s duties to the Processor.
            </div>
            <div className={ 'mt-3' }>
              5.2. The Processor will impose appropriate contractual obligations upon its Personnel Processing
              Controller Data, including relevant obligations regarding confidentiality, data protection and data
              security. Processor shall ensure that Personnel engaged are informed of the confidential nature of
              Controller Data and have received appropriate training with respect to their responsibilities.
            </div>
            <div className={ 'mt-3' }>
              5.3. The Processor has appointed a Data Protection Officer where such appointment is required by
              Applicable Data Protection legislation. The appointed person can be reached at <a
              href={ 'mailTo:privacy@intramark.io' }>privacy@intramark.io</a>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>6. Sub-Processors</strong></h6>
            <div className={ 'mt-3' }>
              6.1. Controller hereby grants the Processor a general written authorization to engage Sub-Processors set
              out <a href={ '/sub-processors' }>https://www.intramark.io/sub-processors</a> (“<strong>Sub-Processor
              Portal</strong>”).
            </div>
            <div className={ 'mt-3' }>
              6.2. Controller may sign up to notifications of changes to the Sub-Processors through the Sub-Processor
              Portal.
            </div>
            <div className={ 'mt-3' }>
              6.3. Processor shall give Controller at least 30 days’ prior notice of the appointment of any new Sub-
              Processor or the replacement of an existing Sub-Processor, including relevant details of the processing
              activities to be performed by such Sub-Processor, by updating the Sub-Processor Portal and providing
              Controller with a notification of such change through the mechanism on the Sub-Processor Portal and
              via email.
              <div className={ 'mt-2' }>
                6.3.1. If, within seven (7) days of receipt of such notice Controller notifies Processor in writing of
                any reasonable objection to the appointment with respect to the protection of Controller
                Data (“<strong>Controller Notice</strong>”), Processor shall postpone the appointment until reasonable
                steps have been taken to address Controller’s objection, which may be achieved by way of, amongst
                others: (i) offering an alternative to provide the Service without using such Sub-Processor; (ii)
                Processor may cease to provide, or Controller may agree, not to use that specific part of the Service
                involving such Sub-Processor; or (iii) take commercially reasonable supplementary measures in
                cooperation with Controller. Objection Notices should be sent to <a
                href={ 'mailTo:privacy@intramark.io' }>privacy@intramark.io</a>
              </div>
              <div className={ 'mt-2' }>
                6.3.2. Where such steps are not reasonably sufficient or available to relieve Controller’s objection and
                no alternative mutually agreeable solution could be reached within thirty (30) days upon receipt of the
                Controller Notice, to the extent that it relates to the Service which require the use of such Sub-
                Processor, either Party may, by written notice to the other party, terminate the applicable Order Form
                and/or Principal Agreement, for which Processor may – in its sole discretion – provide a refund of the
                pro-rata portion of any unused, prepaid Fees under the applicable Order Form (calculated from the
                balance period between termination date and the original term of the Order Form).
              </div>
              <div className={ 'mt-2' }>
                6.3.3. If the Controller does not provide a timely Controller Notice, the Sub-Processors shall be deemed
                approved, and Processor may commence using such Sub-Processor.
              </div>
            </div>
            <div className={ 'mt-3' }>
              6.4. Where a Sub-Processor fails to fulfil its data protection obligations, the Processor shall remain
              fully
              liable to Controller for the performance of the Sub-Processor’s obligations.
            </div>
            <div className={ 'mt-3' }>
              6.5. With respect to each Sub-Processor prior to its appointment (i) Processor shall carry out adequate
              due diligence to ensure that the Sub-Processor is capable of providing the level of protection for
              Controller Data required by the Principal Agreement and this DPA; and (ii) ensure that the arrangement
              between the Processor and the Sub-Processor is governed by a written contract that substantially meets
              the same obligations under this DPA.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>7. Affiliates</strong></h6>
            <div className={ 'mt-3' }>
              7.1. Some of Processor’s obligations may be performed by Processor’s Affiliates, as detailed on the Sub-
              Processor Portal. Controller acknowledges that Processor’s Affiliates may Process Controller Data on
              Processor’s behalf to perform the Service under the Principal Agreement.
            </div>
            <div className={ 'mt-3' }>
              7.2. Processor will be liable for the acts and omissions of its Affiliates to the same extent Processor
              would be liable if performing the Service under the Principal Agreement.7.3. Controller hereby consents
              to Processor’s use of Processor’s Affiliates in the performance of the Service in accordance with the
              terms of this Section 7.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>8. Personal Data Breach</strong></h6>
            <div className={ 'mt-3' }>
              8.1. In the event of a Personal Data Breach, the Processor shall notify Controller of such Personal Data
              Breach without undue delay and at the latest within 48 hours after becoming aware of the Personal
              Data Breach.
            </div>
            <div className={ 'mt-3' }>
              8.2. The Processor shall promptly after becoming aware of a Personal Data Breach:
              <div className={ 'mt-2' }>
                a. Commence an investigation of the Personal Data Breach in order to determine the scope, nature and
                the likely consequences of the Personal Data Breach;
              </div>
              <div className={ 'mt-2' }>
                b. Take appropriate remedial measures in order to mitigate the possible adverse effects of the Personal
                Data Breach and minimize damage resulting therefrom.
              </div>
            </div>
            <div className={ 'mt-3' }>
              8.3. Processor shall promptly provide Controller with such details relating to the Personal Data Breach as
              Controller reasonably requires to comply with its obligations under the Applicable Data Protection
              Legislation.
            </div>
            <div className={ 'mt-3' }>
              8.4. The obligations in this Section 8 shall not apply to incidents that are caused by Controller or
              Controller’s End Users (as defined in the Principal Agreement).
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>9. Rights of Data Subjects</strong></h6>
            <div className={ 'mt-3' }>
              9.1. Processor shall, to the extent legally permitted, promptly notify Controller if it receives a request
              from a Data Subject to exercise the Data Subject’s right of access, right to rectification, restriction of
              processing, erasure, data portability, or to object to processing, each a “<strong>Data Subject
              Request</strong>”. Processor will not respond to any such requests unless authorized to do so by
              Controller or as required under Applicable Data Protection Legislation or under the instructions of a
              Supervisory Authority.
            </div>
            <div className={ 'mt-3' }>
              9.2. Subject to Clause 9.3, the Processor shall provide commercial reasonable assistance to Controller by
              taking appropriate technical and organizational measures for the fulfilment of Controller’s obligation to
              respond to requests for exercising the Data Subjects’ rights as laid down by Applicable Data Protection
              Legislation. Unless prohibited under the Applicable Data Protection Legislation, Controller will reimburse
              Processor with any costs and expenses related to Processor’s provision of such assistance.
            </div>
            <div className={ 'mt-3' }>
              9.3. Controller will provide Processor with the specific identification information (e.g., IP address and
              time of uploading the information to the Processor’s servers) in order for Processor to assist the
              Controller in responding to a Data Subject Request.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>10. Audits</strong></h6>
            <div className={ 'mt-3' }>
              10.1. Processor shall make available to Controller, upon prior written request, all relevant information
              necessary to reasonably demonstrate compliance with its obligations detailed in this DPA.
            </div>
            <div className={ 'mt-3' }>
              10.2. Processor shall allow for and contribute to audits, including inspections on its premises, which in
              no event will be conducted more than once in each calendar year (except following a Personal Data
              Breach) and during regular business hours. The audit may be conducted by Controller, or a third-party
              auditor mandated by Controller, provided that such third-party auditor shall be subject to sufficient
              confidentiality obligations. Controller shall give Processor at least 14 days notice prior to exercising
              its audit rights.
            </div>
            <div className={ 'mt-3' }>
              10.3. Each Party shall bear its own costs in relation to such audit. However, where Controller has
              mandated a third-party auditor to carry out the audit on its behalf, Controller shall bear the costs for
              such third-party auditor.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>11. Data Impact Assessments; Consultations</strong></h6>
            <div className={ 'mt-3' }>
              The Processor shall, upon Controller’s request, provide necessary information in order to allow
              Controller to fulfil its obligations to, where applicable, carry out data protection impact assessments
              (“<strong>DPIAs</strong>”) and prior consultations with the relevant Supervisory Authority under
              Applicable Data Protection Legislation in relation to the processing of Controller Data covered by this
              DPA.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>12. Transfers</strong></h6>
            <div className={ 'mt-3' }>
              12.1 To the extent Processor processes Controller Data in a Third Country, and it is acting as a data
              importer, Processor shall comply with the data importer’s obligations set out in the Controller to
              Processor Clauses, which are hereby incorporated into and form part of this DPA; the Controller shall
              comply with the data exporter’s obligations in such Controller to Processor Clauses; and
              <div className={ 'mt-4' }>
                – for the purposes of Annex I or Part 1 (as relevant) of such Controller to Processor Clauses, the
                parties
                and processing details set out in this DPA and in Error: Reference source not found 1 (Processing
                Details)
                shall apply, and the Start Date is the date of this DPA;
              </div>
              <div className={ 'mt-4' }>
                – if applicable, for the purposes of Part 1 of such Controller to Processor Clauses, the relevant
                Addendum EU SCCs (as such term is defined in the applicable Controller to Processor Clauses) are the
                standard contractual clauses for the transfer of Personal Data to third countries set out in Commission
                Decision 2021/914 of 4 June 2021 (Module 2) as incorporated into this DPA by virtue of this Clause
                Transfers;
              </div>
              <div className={ 'mt-4' }>
                – for the purposes of Annex II or Part 1 (as relevant) of such Controller to Processor Clauses, the
                technical and organizational security measures set out in Clause 2 of this DPA shall apply; and
              </div>
              <div className={ 'mt-4' }>
                – if applicable, for the purposes of: (i) Clause 9 of such Controller to Processor Clauses, Option 2
                (“General written authorization”) is deemed to be selected and the notice period specified in Clause 5.3
                of the DPA shall apply; (ii) Clause 11(a) of such Controller to Processor Clauses, the optional wording
                in
                relation to independent dispute resolution is deemed to be omitted; (iii) Clause 13 and Annex I.C, the
                competent supervisory authority shall be Dutch Data Protection Authority (Authoriteit
                Persoonsgegevens); (iv) Clause 17, Option 1 is deemed to be selected and the governing law shall be
                Dutch law; (v) Clause 18, the competent courts shall be in the Netherlands; and (vi) Part 1 of such
                Controller to Processor Clauses, Processor as importer may terminate the Controller to Processor
                Clauses pursuant to Section 19 of such Controller to Processor Clauses.
              </div>
            </div>
            <div className={ 'mt-3' }>
              12.2 The Controller acknowledges and agrees that Processor may appoint an Affiliate or Sub-Processor
              to Process the Controller Data in a Third Country, in which case Processor shall execute the Processor to
              Processor Clauses with any relevant Sub-Processor (including affiliates) it appoints on behalf of the
              Controller.:
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>13. Deletion; Return</strong></h6>
            <div className={ 'mt-3' }>
              Processor shall promptly, and in any event within 90 days of termination of the Principal Agreement or
              upon Controller’s request, delete or return all copies of Controller Data, except where such copies are
              required to be retained in accordance with the Applicable Data Protection Legislation and provided that
              Processor shall ensure the confidentiality of all such Controller Data. Upon prior written request of
              Controller, Processor shall provide written documentation that is has complied with its obligation
              herein.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>14. General Terms</strong></h6>
            <div className={ 'mt-3' }>
              14.1. The Parties this DPA agree to negotiate in good faith modifications to this DPA if changes are
              required for Processor to continue to process the Controller Data as contemplated by this DPA in
              compliance with the Applicable Data Protection Legislation or to address the legal interpretation of the
              Applicable Data Protection Legislation, including (i) to comply with any guidance on the interpretation of
              any of the respective provisions of the Applicable Data Protection Legislation; (ii) the Standard
              Contractual Clauses or any other mechanisms or findings of adequacy are invalidated or amended, or
              (iii) if changes to the membership status of a country in the European Union or the European Economic
              Area require such modification.
            </div>
            <div className={ 'mt-3' }>
              14.2. The Parties to this DPA hereby submit to the choice of jurisdiction stipulated in the Principal
              Agreement with respect to any disputes or claims howsoever arising under this DPA, including disputes
              regarding its existence, validity or termination or the consequences of its nullity.
            </div>
            <div className={ 'mt-3' }>
              14.3. Should any provision of this DPA be invalid or unenforceable, then the remainder of this DPA shall
              remain valid and in force. The invalid or unenforceable provision shall be either (i) amended as
              necessary to ensure its validity and enforceability, while preserving the Parties’ intentions as closely
              as possible or, if this is not possible, (ii) construed in a manner as if the invalid or unenforceable
              part had never been contained therein.
            </div>
          </Col>
        </Row>
      </Container>
      <Container className={ 'pt-4 pb-5' }>
        <Row className={ 'justify-content-center' }>
          <Col sm={ 6 } className={ 'text-start' }>
            <div className={ 'mt-4' }>
              <h5><strong>Annex 1 – Details of Processing</strong></h5>
            </div>
            <div className={'mt-3'}>
              This Annex 1 includes details of the Processing of Controller Data as required by Article 28(3) GDPR.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6>
              <strong><i>1. Subject matter, frequency, and duration of the Processing of Controller Data</i></strong>
            </h6>
            <div>
              The subject matter and duration of the Processing of the Controller Data are set out in the Principal
              Agreement and this Annex. The transfer is a continuous transfer.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6>
              <strong><i>2. The nature and purpose of the Processing of Controller Data</i></strong>
            </h6>
            <div>
              Processor has developed, and owns Software as a Service (SaaS) digital platforms that collect real-time
              feedback from customer employees, deliver micro eLearning; submit and manage requests to meet with
              Ombuds, among other services (“Service” – as further defined in the Principal Agreement).
            </div>
            <div className={ 'mt-3' }>
              The Controller Data is collected by Processor when an End User or Authorized Representative (as
              defined below) uses the Service.
            </div>
            <div className={ 'mt-3' }>
              The Controller Data is processed on behalf of the Controller for the purpose of providing the Service, the
              ongoing operation thereof, and/or for security purposes.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6>
              <strong><i>3. The types of Controller Data to be Processed</i></strong>
            </h6>
            <div className={ 'mt-3' }>
              <ul>
                <li>
                  Controller’s employee (End-Users) information: Employee ID, First Name, Last Name, Work Email,
                  Job Title, Internal Job Level, Hire Date, Manager Work Email, Job Class (e.g., 30-39 Hour, 20-29
                  Hour), Team Name, Department Name, Department Type (e.g., customer facing, support),
                  Office Location.
                </li>
                <li className={'mt-3'}>
                  End-Users’ feedback responses to prompts, views of micro eLearning, meeting invitations with
                  employee, agent or contractor of the Processor.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6>
              <strong><i>4. The categories of Data Subject to whom the Controller Data relates</i></strong>
            </h6>
            <div>
              Data subjects are the End-Users of the Service.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6>
              <strong><i>5. The obligations and rights of Controller</i></strong>
            </h6>
            <div>
              The obligations and rights of Controller are set out in this DPA, the Principal Agreement and this Annex.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6>
              <strong><i>6. Retention Periods</i></strong>
            </h6>
            <div>
              Processor will retain Controller Data it processes hereunder only for as long as required to provide the
              Service pursuant to the Principal Agreement unless otherwise agreed in writing by the Parties, after a
              request from the Controller to delete any Controller Data or upon termination or expiration of the
              Principal Agreement, process will begin that permanently deletes the data in accordance with the
              timelines set forth in the table below. Once initiated, this process cannot be reversed, and data will be
              permanently deleted.
            </div>
            <div className={'mt-3'}>
              <Table bordered responsive>
                <thead className={'bg-dark font-white'}>
                  <tr>
                    <td className={'p-3'}>Type</td>
                    <td className={'p-3'}>Timeline for Deletion (after deletion process
                      begins) for Cancellation, Termination or Migration</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={'p-3'}>Backups</td>
                    <td className={'p-3'}>30 days</td>
                  </tr>
                  <tr>
                    <td className={'p-3'}>Logs</td>
                    <td className={'p-3'}>60 days</td>
                  </tr>
                  <tr>
                    <td className={'p-3'}>Access Logs</td>
                    <td className={'p-3'}>2 years</td>
                  </tr>
                  <tr>
                    <td className={'p-3'}>Data in Modal Platform</td>
                    <td className={'p-3'}>90 days</td>
                  </tr>
                  <tr>
                    <td className={'p-3'}>Communications regarding requests for data deletion and exercise of individual rights</td>
                    <td className={'p-3'}>At least 24 months</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}