import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const Introduction: React.FC = () => {
  return (
    <Row className={ 'justify-content-center mt-5' }>
      <Col sm={ 6 } className={ 'text-justify' }>
        <h6><strong>I. Introduction</strong></h6>
        <div>
          At Intramark, data privacy is important to us. This Intramark Privacy Policy (“Privacy Policy”) details
          our
          privacy practices for the activities described in this Privacy Policy. Please take the time to read this
          Privacy Policy carefully in order to understand how we collect, share, and otherwise process information
          relating to individuals (“Personal Data”), and to learn about your rights and choices regarding our
          processing of your Personal Data.
        </div>
        <div className={ 'mt-3' }>
          If you are a California resident, please review the section of this Privacy Policy for California
          residents.
        </div>
        <div className={ 'mt-3' }>
          In this Privacy Policy, “Intramark,” “we,” “our,” and “us” each mean Intramark, Inc. and the applicable
          Intramark affiliate(s) involved in the processing activity. The addresses of our offices, where Intramark,
          Inc. and our affiliates are located, can be found at <a
          href={ '/contact' }>https://www.intramark.io/contact</a>.
        </div>
      </Col>
    </Row>
  )
}