import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Header } from '../../components/Header/Header';
import './ContactUsScreen.scss';
import { ContactUs } from '../../models/ContactUs';
import ContactUsService from '../../services/ContactUsService';

interface IOptions {
  value: string;
  label: string;
}

export const ContactUsScreen: React.FC = () => {

  const navigate = useNavigate();

  const { state } = useLocation();

  const options: IOptions[] = [
    { value: 'consultation', label: 'Book a Demo' },
    { value: 'partnership', label: 'Explore partnership opportunity' },
    { value: 'press', label: 'PR / Press inquiry' },
    { value: 'general', label: 'Other inquiry' },
  ]

  const [inquiryType, setInquiryType] = useState<IOptions>(options[0]);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');

  const [validated, setValidated] = useState(false);
  const [showSuccess, toggleShowSuccess] = useState(false);
  const [showError, toggleShowError] = useState(false);

  const onSelectionChange = (newValue: any) => {
    setInquiryType(newValue)
  }

  useEffect(() => {
    if (state && state.type) {
      const selectedOption = options.filter(option => option.value === state.type);
      setInquiryType(selectedOption[0])
    }
  }, [state]);

  const sendContactUsInfo = async () => {
    try {
      if (!inquiryType || !email || !firstName || !lastName) {
        return;
      }
      const contactUsObj: ContactUs = {
        firstName,
        lastName,
        email,
        inquiryType: inquiryType.label,
        message
      };
      await ContactUsService.saveContactUsInfo(contactUsObj);
      toggleShowSuccess(true);
      setTimeout(() => {
        toggleShowSuccess(false);
      }, 4000);

      setEmail('');
      setFirstName('');
      setLastName('');
      setInquiryType(options[0]);
      setMessage('');
      setValidated(false);
    } catch (error) {
      toggleShowError(true);
      setTimeout(() => {
        toggleShowError(false);
      }, 4000);
    }
  }

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    if (form.checkValidity() === true) {
      if (inquiryType?.value === 'partnership' || inquiryType?.value === 'consultation') {
        navigate('/schedule', {state: { type: inquiryType.value, email, name: firstName + ' ' + lastName}})
      } else {
        await sendContactUsInfo();
      }
    }
  }

  return (
    <section>
      <Header />
      <Container className={'main-container'}>
        <Row className={'fade-in text-start'}>
          <Col sm={5}>
            <h2>Contact us</h2>
            <div className={ 'mt-4' }>
              By submitting this form you consent to receive marketing communications about Intramark<span
              className={ 'font-primary' }>^</span> products, services and events. Your data will be processed in
              accordance with our <a href={'/privacy-policy'}>privacy policy</a>. You may unsubscribe at any time.
            </div>
          </Col>
          <Col sm={ 2 }/>
          <Col sm={5}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>First Name <sup className={'text-danger'}>*</sup></Form.Label>
                <Form.Control
                  type="text"
                  value={firstName}
                  onChange={event => setFirstName(event.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your first name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="lastName">
                <Form.Label>Last Name <sup className={'text-danger'}>*</sup></Form.Label>
                <Form.Control
                  type="text"
                  value={lastName}
                  onChange={event => setLastName(event.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your last name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Company Email <sup className={'text-danger'}>*</sup></Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your valid email address.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="inquiryType">
                <Form.Label>Subject <sup className={'text-danger'}>*</sup></Form.Label>
                <Select
                  options={options}
                  classNamePrefix={'select'}
                  onChange={onSelectionChange}
                  value={inquiryType}
                  required
                />
              </Form.Group>
              {(inquiryType?.value === 'press' || inquiryType?.value === 'general') && (
                <div>
                  <Form.Group className="mb-3" controlId="message">
                    <Form.Label>Message <sup className={'text-danger'}>*</sup></Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={message}
                      onChange={event => setMessage(event.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your message.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    variant={'primary'}
                    className={'mt-3'}
                    type={'submit'}
                  >
                    Send
                  </Button>
                </div>
              )}
              {(inquiryType?.value === 'partnership' || inquiryType?.value === 'consultation') && (
                <Button
                  variant={'primary'}
                  className={'mt-3'}
                  type={'submit'}
                >
                  Schedule a call
                </Button>
              )}
              <div className={'mt-3'}>
                {showSuccess && (
                  <Form.Text id="success-block" className={'text-primary'}>
                    Your message has been sent.
                  </Form.Text>
                )}
                {showError && (
                  <Form.Text id="error-block" className={'text-danger'}>
                    An error occurred while sending a message. Please try again later!
                  </Form.Text>
                )}
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
