import React, { useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Subscribe as SubscribeModel } from '../../models/Subscribe';
import SubscribeService from '../../services/SubscribeService';

export const Subscribe: React.FC = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [showSuccess, toggleShowSuccess] = useState(false);
  const [showError, toggleShowError] = useState(false);

  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      await subscribeToUpdates();
    }
  }
  const subscribeToUpdates = async () => {
    try {
      const subscription: SubscribeModel = {
        email
      };
      await SubscribeService.saveSubscription(subscription);
      toggleShowSuccess(true);
      setTimeout(() => {
        toggleShowSuccess(false);
      }, 4000);

      setEmail('');
      setValidated(false);
    } catch (error) {
      toggleShowError(true);
      setTimeout(() => {
        toggleShowError(false);
      }, 4000);
    }
  }

  return (
    <Container className={'main-container'}>
      <Row>
        <Col md={6} sm={12}>
          <Row className={'text-start'}>
            <Col sm={12}>
              <h2>
                Have questions?
              </h2>
              <h4 className={'mt-4'}>
                Contact us
              </h4>
            </Col>
            <Col sm={12} className={'mt-4'}>
              We will promptly set-up a consultation, explore partnership, or address your inquiries. We look forward to hearing from you.
            </Col>
          </Row>
          <Row className={'text-start'}>
            <Col sm={4} className={'mt-4'}>
              <Button
                className={'btn-block cursor-pointer'}
                onClick={() => navigate('/contact')}
              >
                Contact
              </Button>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className={'mb-md-0 mb-5'} />
        <Col md={4} sm={12}>
          <Row className={'text-start'}>
            <Col sm={12} className={'font-bold'}>
              Subscribe for updates
            </Col>
            <Col sm={12} xs={10} md={12}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <InputGroup className="mt-2" hasValidation>
                  <Form.Control
                    placeholder="Email address"
                    aria-label="Email address"
                    aria-describedby="basic-addon2"
                    type={'email'}
                    value={email}
                    required
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <Button variant="primary" id="button-addon2" type={'submit'}>
                    Submit
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    Please enter your valid email address.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form>
              {showSuccess && (
                <Form.Text id="passwordHelpBlock" className={'text-primary fade-in'}>
                  You are subscribed for updates.
                </Form.Text>
              )}
              {showError && (
                <Form.Text id="passwordHelpBlock" className={'text-danger fade-in'}>
                  An error occurred while subscribing. Please try again later!
                </Form.Text>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}