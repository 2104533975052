import React, { useEffect } from 'react';
import { Card, Carousel, Col, Container, Image, Row } from 'react-bootstrap';
import { Header } from '../../components/Header/Header';
import './MicroELearningScreen.scss';
import { Subscribe } from '../../components/Subscribe/Subscribe';
import {
  brainCircuit,
  browser,
  jit,
  laptopCode, peopleArrows,
  shareNodes,
  sparkles,
} from '../../utils/images';

export const MicroELearningScreen: React.FC = () => {

  return (
    <section>
      <Header />
      <section className={'fade-in'}>
        <Container className={'main-container'}>
          <Row className={'align-items-center'}>
            <Col sm={6} xs={12} className={'justify-content-center mb-md-0 mb-5'}>
              <Carousel
                fade
                interval={3000}
                indicators={false}
              >
                <Carousel.Item>
                  <Image
                    className="d-block w-100"
                    src={ jit }
                    alt="First slide"
                  />
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col sm={6} xs={12} className={'text-start'}>
              <div>
                <h2>Just-in-time (JIT) micro eLearning</h2>
                <div className={'mt-4'}>
                  Micro eLearning are 60-90 second videos, among other formats, addressing a single concept or idea that
                  can be applied in the workplace within 48 hours. Micro eLearning are delivered through our
                  Vibe<span className={'font-primary'}>^</span> modal as a creative solution to maximize engagement.
                </div>
                <Row className={'mt-4'}>
                  <Col xs={2} className={'d-flex justify-content-center align-items-center icon-border-right'}>
                    <Image
                      src={ laptopCode }
                      width={ 40 }
                    />
                  </Col>
                  <Col xs={10}>
                    Demo productivity tool features to maximize tool utility on the job
                  </Col>
                </Row>
                <Row className={'mt-4'}>
                  <Col xs={2} className={'d-flex justify-content-center align-items-center icon-border-right'}>
                    <Image
                      src={ sparkles }
                      width={ 40 }
                    />
                  </Col>
                  <Col xs={10}>
                    Upskill on concepts relevant to employee lifecycle, core skills, DEI & Belonging principles
                  </Col>
                </Row>
                <Row className={'mt-4'}>
                  <Col xs={2} className={'d-flex justify-content-center align-items-center icon-border-right'}>
                    <Image
                      src={ shareNodes }
                      width={ 40 }
                    />
                  </Col>
                  <Col xs={10}>
                    Knowledge share best practices tied to employee experience themes
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={'bg-light fade-in'}>
        <Container className={'main-container'}>
          <Row className={'text-start'}>
            <Col sm={4} xs={12} className={'wrapper mb-md-0 mb-5'}>
              <div className={'title-col'}>
                <h2>How customers use <br /> Micro eLearning</h2>
              </div>
            </Col>
            <Col sm={4} className={'ps-3 pe-3 pb-3 wrapper'}>
              <Card>
                <Card.Title className={'text-center p-2 pt-5'}>
                  <Image
                    src={ browser }
                    width={ 44 }
                  />
                </Card.Title>
                <Card.Body>
                  <div className={'text-center'}>
                    <h5><strong>Demo how to use features in productivity tools</strong></h5>
                    <span>Short video demonstration for how to use advanced features in productivity tools such as setting up project templates in task management applications</span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} className={'ps-3 pe-3 pb-3 wrapper'}>
              <Card className={'h-100'}>
                <Card.Title className={'text-center p-2 pt-5'}>
                  <Image
                    src={ brainCircuit }
                    width={ 42 }
                  />
                </Card.Title>
                <Card.Body>
                  <div className={'text-center'}>
                    <h5><strong>Upskill on core concepts related to job role</strong></h5>
                    <span>Short video on a key concept within a specific agile methodology relevant to project managers within a specific industry</span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={6} />
            <Col sm={4} className={'ps-3 pe-3 pt-3 wrapper'}>
              <Card>
                <Card.Title className={'text-center p-2 pt-5'}>
                  <Image
                    src={ peopleArrows }
                    width={ 56 }
                  />
                </Card.Title>
                <Card.Body>
                  <div className={'text-center'}>
                    <h5><strong>Knowledge share among teams</strong></h5>
                    <span>Short video sharing marketing technique developed by another department to increase engagement on social media</span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={2} />
          </Row>
        </Container>
      </section>
      <section>
        <Subscribe />
      </section>
    </section>
  )
}