import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const DataTransfer: React.FC = () => {
  return (
    <Row className={ 'justify-content-center mt-5' }>
      <Col sm={ 6 } className={ 'text-justify' }>
        <h6><strong>VII. International Data Transfers</strong></h6>
        <div>
          Your Personal Data may be collected, transferred to, processed, and stored by us in the United States, and by
          our affiliates, service providers, and third parties that are based in other countries. The addresses of our
          offices where Intramark, Inc. and its affiliates are located can be found online at
          <a href={ '/contact' } className={ 'ms-1' }>https://www.intramark.io/contact.</a>
        </div>
        <div className={ 'mt-3' }>
          Some of the countries where your Personal Data may be processed, including the United States, are not subject
          to an adequacy decision by the European Commission or your local legislature and/or regulator, and may lack
          data protection laws as comprehensive as or may not provide the same level of data protection as your
          jurisdiction, such as the European Economic Area, the United Kingdom, or Japan. For example, as of the
          effective date of this policy, the United States does not have a federal privacy law that covers all types of
          data; however, privacy is regulated by federal and state agencies and by various state laws. In light of
          regional differences, Intramark has put in place various safeguards and the security measures described above.
          For example, when we share Personal Data, we take reasonable steps so that the recipient of your Personal Data
          offers an adequate level of data protection, for example, by entering into the appropriate agreements
          containing relevant data protection provisions or we will ask you for your prior consent to such international
          data transfers.
        </div>
      </Col>
    </Row>
  )
}