import { ContactUs } from '../models/ContactUs';
import request from '../utils/axios';

const CONTACT_US_ENDPOINT = '/contact-us';

export default class ContactUsService {

  static saveContactUsInfo = (contactUs: ContactUs) => {
    return request({
      url: CONTACT_US_ENDPOINT,
      method: 'post',
      data: {...contactUs},
    });
  };
}