import React from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { Header } from '../../components/Header/Header';

export const VerificationProcessScreen: React.FC = () => {

  return (
    <section>
      <Header/>
      <Container className={ 'pt-4 pb-5' }>
        <Row className={ 'justify-content-center' }>
          <Col sm={ 6 } className={ 'text-start' }>
            <div className={ 'mt-4' }>
              <h3><strong>Verification Process for Individual Rights Requests</strong></h3>
            </div>
            <div>
              <Badge bg={ 'light' } text={ 'secondary' }>Effective Date: February 15, 2023</Badge>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <div>
              We have reasonable methods in place for verifying rights requests for individuals who choose to
              exercise these rights, such as a request to know or delete their personal data. The process described
              here applies to our role as a business, not as a service provider to our customers. We are unable to
              respond to requests in our role as a service provider to our customers. We encourage you to submit any
              such requests directly to the business with whom you interact or have a direct relationship with to use
              Intramark services.
            </div>
            <div className={ 'mt-3' }>
              For requests we receive as a business, we will match the identifying information provided by you to the
              personal information we already maintain to verify your identity. At minimum, we will ask for your
              name, email address, country, and state or province. When verifying requests, our verification standards
              vary depending on the sensitivity of the request. If we cannot verify your identity, we may deny your
              request. In some cases, we may require additional information, in which case we will contact you.
            </div>
            <div className={ 'mt-3' }>
              If you are an authorized agent making a request to know or delete, we also require you to email <a
              href={ 'mailTo:privacy@intramark.io' }>privacy@intramark.io</a> to: (i) provide us with a copy of your
              written authorization to confirm your right to make the request and direct the requesting individual to
              verify their identity directly with us or, if applicable, (ii) provide a copy of your power of attorney to
              exercise these rights on behalf of another.
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}