import React, { useRef } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { Header } from '../../components/Header/Header';
import { CaliforniaResidents } from './CaliforniaResidents';
import { Children } from './Children';
import { Data } from './Data';
import { DataDisclosed } from './DataDisclosed';
import { DataRetention } from './DataRetention';
import { DataTransfer } from './DataTransfer';
import { DataUse } from './DataUse';
import { InformationChoices } from './InformationChoices';
import { Introduction } from './Introduction';
import { Roles } from './Roles';
import { SecurityPosture } from './SecurityPosture';

export const PrivacyScreen: React.FC = () => {
  const elemRef = useRef(null);
  const contactElemRef = useRef(null);
  return (
    <section>
      <Header/>
      <Container className={ 'pt-4 pb-5' }>
        <Row className={ 'justify-content-center' }>
          <Col sm={ 6 } className={ 'text-start' }>
            <div className={ 'mt-3' }>
              <h3><strong>Privacy Policy</strong></h3>
            </div>
            <div>
              <Badge bg={ 'light' } text={ 'secondary' }>Effective Date: February 15, 2023</Badge>
            </div>
          </Col>
        </Row>
        <Introduction/>
        <Roles/>
        <Data elemRef={elemRef} />
        <DataUse elemRef={elemRef}/>
        <DataDisclosed elemRef={elemRef}/>
        <SecurityPosture/>
        <DataTransfer/>
        <Children elemRef={contactElemRef}/>
        <DataRetention/>
        <div ref={elemRef}>
          <InformationChoices />
        </div>
        <CaliforniaResidents/>
        <div ref={contactElemRef}>
          <Row className={ 'justify-content-center mt-5' }>
            <Col sm={ 6 } className={ 'text-justify' }>
              <h6><strong>XII. How to Contact Intramark</strong></h6>
              <div>
                If you would like to contact us with questions or concerns about our privacy policies and practices, you
                may contact us via any of the following methods:
              </div>
              <div className={ 'mt-3' }>
                Online Form: Click <a href={ '/contact' }>here</a>.
              </div>
              <div className={ 'mt-3' }>
                Email: <a href={ 'mailTo:privacy@intramark.io' }>privacy@intramark.io</a>
              </div>
              <div className={ 'mt-3' }>
                Mailing Address:
                <div>
                  Intramark, Inc.
                </div>
                <div>
                  ATTN: Intramark Data Protection Officer (Intramark Privacy Team)
                </div>
                <div>
                  1 Dry Hill Ct
                </div>
                <div>
                  Norwalk, CT 06851
                </div>
              </div>
              <div className={ 'mt-3' }>
                <strong>Accessibility</strong>
                <div>
                  If you are not able to access our form, you may request that a copy be provided to you in an
                  alternative format by emailing <a href={ 'mailTo:privacy@intramark.io' }>privacy@intramark.io</a>.
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>XIII. Changes to the Policy</strong></h6>
            <div>
              This Privacy Policy may be updated from time to time, to reflect changes in our practices, technologies,
              additional factors, and to be consistent with applicable data protection and privacy laws and principles,
              and other legal requirements. If we do make updates, we will update the “effective date” at the top of
              this Privacy Policy webpage. If we make a material update, we may provide you with notice prior to the
              update taking effect, such as by posting a conspicuous notice on our website or by contacting you using
              the email address you provided.
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}