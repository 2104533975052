import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const Data: React.FC<{ elemRef: any }> = ({elemRef}) => {

  const scrollToElement = () => {
    elemRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  return (
    <>
      <Row className={ 'justify-content-center mt-5' }>
        <Col sm={ 6 } className={ 'text-justify' }>
          <h6><strong>III. Personal Data We Collect and Data Sources</strong></h6>
          <div className={ 'mt-3' }>
            <h6><strong>Covered Data Processing Activities</strong></h6>
            This Privacy Policy applies to the processing of Personal Data that we collect in the following ways,
            as detailed in this section.
          </div>
          <div className={ 'mt-3' }>
            We collect information about you when you provide it to us, when you interact with our websites and
            electronic systems, when you attend events and visit our offices, and when other sources provide it to us,
            as further described below.
          </div>
          <div className={ 'mt-3' }>
            <div className={ 'text-decoration-underline' }>Information you provide to us</div>
            Based on our current practices (and including our practices over the last 12 months),
            we collect the following categories of information about you:
          </div>
          <div className={ 'mt-3' }>
            <i>Contact and Professional Data</i>:
            We collect contact and/or professional data about you in person, through communications,
            including communications from you or your colleagues, and through our websites.
            For example, you provide your contact and professional information to us when you sign up to learn more
            about Intramark’s products and services, download content, register for an event, and visit our offices.
            If you attend an event, we may also receive contact and professional details about you when you
            choose to scan your attendee badge or by providing a business card or other method(s) whereby you share
            Personal Data with us. Typically, contact data includes your name and contact methods,
            such as telephone number, email address, and office or other mailing address, and professional data
            includes details such as the organization you are affiliated with, your job title, and industry.
          </div>
          <div className={ 'mt-3' }>
            <i>Administrator Data</i>:
            When you sign up for an account to try Intramark, subscribe to any Intramark service via Intramark or
            another entity (such as a marketplace or authorized reseller), have the ability to submit a support
            request, or are designated an administrator of any part of the Intramark Service, then information is
            provided to us about you (“Administrator Data”). Administrator Data usually includes your name, email
            address, phone number, address, billing information, business contact information, credentials
            information (including Intramark training and credentials), subscription and service configurations
            you select, and other details you may provide to us about you or include in your profiles in Intramark
            communities and other support portals. We may also receive any Personal Data you share via tooling used
            to provide support, e.g., videoconferencing or other communication methods you participate in.
          </div>
          <div className={ 'mt-3' }>
            <i>Biographical, Community, and Support Data</i>:
            We may also collect various types of biographical, community, and support Personal Data from you via our
            help center and community support forums. For example, if you register for an online community that we
            host, we may ask you to provide a username, photo and/or biographical information, such as your
            occupation, organization name and areas of expertise. Additionally, you may provide Personal Data to us
            when you create user-generated content (for example, by posting in a forum), provide Intramark with
            feedback, or when you participate in interactive features, trainings, online surveys, contests,
            promotions, sweepstakes, activities, or events. Intramark may receive Personal Data and Administrator
            Data in connection with an administrator's request for support for the Intramark service.
          </div>
          <div className={ 'mt-3' }>
            <i>Job Applicant Data</i>:
            We collect contact and professional information, including your resume with educational and
            work background, that you provide when you apply for a job with Intramark. We may also collect sensitive
            information, like your Social Security Number or other government identifier, racial or ethnic origin,
            or other such Personal Data that you provide in connection with your job application.
          </div>
          <div className={ 'mt-3' }>
            <i>Contract and Payment Data</i>:
            We may receive contract details (like signatures) from you or your organization and use payment
            processing services to collect payment and billing information, which may contain Personal Data
            such as billing name, billing address and payment card details, in connection with some of our products
            and services.
          </div>
          <div className={ 'mt-3' }>
            <i>Audio, Electronic, or Visual Data</i>:
            If you attend an Intramark in-person or virtual event or agree to be recorded in a telephone or video
            meeting, we may record some or all of that event or meeting. For events, we may document the event in
            various ways, such as by taking photos at the event, interviewing you at the event, or recording your
            participation in a live question-and-answer or other interactive session. We use this information for
            business and marketing purposes to better inform the public about Intramark, its events, and provide
            testimonials about our products and services.
          </div>
          <div className={ 'mt-3' }>
            <i>Consumer Products</i>:
            If you use consumer products made available by Intramark (“Intramark Consumer Products”),
            then we may receive various types of information and content from you that you choose to share,
            including contact information (such as your first and last name, email address, and phone number),
            additional multi-factor authentication factor setup details, content you upload (such as identification
            or other documentation), and information regarding the websites and applications that you visit and use
            through Intramark Consumer Products for authentication. We also receive Ancillary Data, including device
            data, Usage Data, and metadata, as described below for the purposes described below.
          </div>
          <div className={ 'mt-3' }>
            Depending on your jurisdiction, if we collect sensitive data from you, we will do so by providing you
            with additional notice or confirming your consent upon collection.
          </div>
        </Col>
      </Row>
      <Row className={ 'justify-content-center mt-5' }>
        <Col sm={ 6 } className={ 'text-justify' }>
          <h6><strong>Personal Data We Collect From Other Sources</strong></h6>
          <div>
            In the course of doing business (and over the 12 months preceding the effective date of this Privacy
            Policy), we receive Personal Data and other information from other third parties for our business or
            commercial purposes. This information varies and typically falls into a few categories:
          </div>
          <div className={ 'mt-3' }>
            <ul>
              <li>
                Business contact information (such as name, job title, business email, phone number, and address),
                social profile (such as LinkedIn or XING) including other details about your organization for sales
                and marketing purposes, to better inform you about Intramark products and services;
              </li>
              <li className={ 'pt-2' }>
                Third-party platform usernames and identifying information;
              </li>
              <li className={ 'pt-2' }>
                Details about you as a job candidate (which may include your name, resume, educational and
                work history, criminal history information, and feedback) as permitted under law; and
              </li>
              <li className={ 'pt-2' }>
                Data used for security purposes to protect our products and services.
              </li>
            </ul>
          </div>
          <div className={ 'mt-3' }>
            We receive business contact information that contains Personal Data for commercial purposes,
            including details about your organization from third parties for marketing and business intelligence,
            such as analyzing business opportunities, identifying and communicating with potential customers, and
            providing our audience with more relevant content and advertising. Typically, we receive this information
            about you from a few sources, such as: (i) third-party marketing initiatives, such as events where we are
            a sponsor, or website forms hosted by third parties that may provide content about us; (ii) instances
            when you consent to having your attendee badge scanned at an event hosted by us or another entity;
            (iii) companies, such as information aggregators and similar entities, from whom we have
            licensed business contact information; (iv) referrals; or (v) resellers and channel partners,
            including those that offer joint marketing services. In some situations, we may combine such business
            contact information with other non-personal and Personal Data we possess or that you have provided to us.
            For example, we may combine business contact details with details about your organization, such as its
            address or revenue range, and analyze this information for business opportunities or use this to send you
            tailored content.
          </div>
          <div className={ 'mt-3' }>
            We also receive Personal Data to help with threat intelligence and to protect the safety and security of
            our service and our customers’ applications, such as breached website credentials from other entities.
            We use this Personal Data for various purposes, such as for security and fraud detection purposes and to
            enable our customers to configure the settings within the Intramark products and services to notify their
            administrators and users if the data that they put into the Intramark service is found within this data
            set.
          </div>
          <div className={ 'mt-3' }>
            We also receive information from third-party platforms for various business purposes such as
            organizational credit information, program management, or technical reasons. For example, we may receive
            credit information about an organization that includes the names of individuals. If you participate in an
            open source project or our bug bounty program, we may receive details about you, such as your username or
            pull requests, to help us manage your participation in the project or program and provide you with
            updates.
          </div>
          <div className={ 'mt-3' }>
            If you are a candidate applying for a job at Intramark, we may receive Personal Data about you from
            third parties for business purposes, such as through background checks (educational, employment,
            criminal, and financial information), publicly-available sources (like social media accounts,
            including LinkedIn for identifying candidates), feedback about your application and from interviews,
            and other third parties that may provide feedback about your application.
          </div>
          <div className={ 'mt-3' }>
            For our professional services work, as a processor or service provider, Intramark may also receive
            Personal Data about you to perform its obligations under its contract with a third party. Intramark
            partners may also share your business contact information with Intramark as part of their recommendation
            to your organization to become an Intramark customer. If Intramark is interested in partnering with,
            acquiring, investing in, or partners with, acquires, or invests in your employing or retaining
            organization, Intramark may receive Personal Data about you through the (potential or completed)
            transaction for its business purposes.
          </div>
        </Col>
      </Row>
      <Row className={ 'justify-content-center mt-5' }>
        <Col sm={ 6 } className={ 'text-justify' }>
          <h6><strong>Device Data, Usage Data, Metadata, and Diagnostic Data We Collect</strong></h6>
          <div className={ 'text-decoration-underline mt-3' }>
            Explanation of Device Data, Usage Data, Diagnostic Data, and Other Metadata and Technology Used
          </div>
          <div className={ 'mt-1' }>
            Like most websites, applications, and software across the Internet, Intramark collects certain
            Personal Data. This type of data collection allows us to better understand how individuals use and
            the performance of our websites, products and services. For example, we may collect metadata about you,
            including technical data about your performance or use of our website, products and services.
            We may also collect device data about you to help us determine that users from one type of device use
            our websites, products and services in different ways than users of a different type of device,
            which in turn allows us to troubleshoot and investigate the performance of our products and services,
            improve our websites, products, and services, such as through optimizing the screen size of Intramark
            mobile applications, or making sure that our customers’ users have a more efficient user experience.
            We may collect these types of Personal Data as part of the services we provide to customers as well as
            in connection with your use of Intramark Consumer Products.
          </div>
          <div className={ 'mt-3' }>
            One common technology we use to collect metadata that may be considered Personal Data is our use of
            cookies. Cookies are small text files that are placed on your web browser and that help us recognize your
            browser or device as a unique visitor in different ways based on the type of cookie. The three main types
            of cookies are:
          </div>
          <div className={ 'mt-3' }>
            <i>Essential cookies</i>.
            Essential cookies are required for website functionality and security. For example, authentication,
            security, and session cookies may be required for our website or products to work.
          </div>
          <div className={ 'mt-3' }>
            <i>Functional cookies</i>.
            We use functional cookies to help enhance our websites’ performance, for market research,
            or other analytics or advertising that is not tied to a specific individual. For example, we may use
            Google analytics to help us track how many individuals visited our websites. We may also utilize HTML5
            local storage cookies for the reasons described in this section. These types of cookies are different
            from browser cookies in the amount and type of data they store and how they store it.
          </div>
          <div className={ 'mt-3' }>
            <i>Targeting or advertising cookies</i>.
            We use targeting and advertising cookies to help us understand our marketing efforts and to reach
            potential customers across the web. For example, we contract with third-party advertising networks that
            may track your activity over time and across different channels, including our websites, email activity,
            and other websites and applications that display advertisements. They may use this tracking information
            to understand and predict your interests, to display an advertisement for Intramark on another website,
            or email you with a marketing communication for an Intramark product.
          </div>
          <div className={ 'mt-3' }>
            If you would like to manage your cookies and your permissions to share data with cookie providers,
            please visit the section below on <span className={ 'link-text' } onClick={ scrollToElement }>Your Information Choices</span>.
          </div>
          <div className={ 'mt-3' }>
            A second common technology we use to collect metadata that may be considered Personal Data is beacon
            technology. We use beacons in our websites and in email communications to you. Beacons provide us with
            information about your activity and help us to improve our business operations and strategy, such as by
            understanding our email communications’ functionality and improving our websites and content. For example,
            if you click on a marketing email we send to you about a new product or service, the beacon will provide
            signals to us that you and your organization may be interested in learning more. If you would like to
            manage your email subscriptions with us, please visit the section below
            on <span className={ 'link-text' } onClick={ scrollToElement }>Your Information Choices</span>.
          </div>
        </Col>
      </Row>
      <Row className={ 'justify-content-center mt-5' }>
        <Col sm={ 6 } className={ 'text-justify' }>
          <div className={ 'text-decoration-underline mt-3' }>
            Data Collected from the Intramark Service and Ancillary Data and Diagnostic Data
          </div>
          <div className={ 'mt-1' }>
            We offer products that collect both a) electronic data submitted by or on behalf of Customer,
            or an Authorized Affiliate, to the Intramark Service (“Customer Data”) and b) data derived from the
            usage of Intramark products and services, including data regarding service configurations and applications
            utilized in connection with the service, support data, operational data, log data and the performance
            results for the service (“Usage Data”). Our collection of both types of data enables us to provide and
            innovate upon the Intramark Service, which in turn allows us to act as a service provider to our
            customers and to continuously improve upon the services we provide to our customers and users.
            In conjunction with the products we make available to our customers, we may collect additional data,
            such as user-agent and browser version, IP address, the URLs you visit (such as to determine whether we
            can help you manage your credentials for such URLs), logs of your usage and click activities, logs about
            your login history, identity confirmation, and device data (such as whether your device is managed by an
            administrator, the operating system installed on the device, certain device configurations, and similar
            device or version information). Collectively, we refer to this data as “Ancillary Data”. Some of the
            Ancillary Data, including Usage Data, that we receive is dependent on your organization’s policies and
            settings and what information it permits to be shared with Intramark. Intramark uses Ancillary Data to
            improve security and to provide and improve its products to customers, including to better understand
            customer behavior in order to create new features and provide threat-related insights for our customers.
          </div>
          <div className={ 'mt-3' }>
            Some of these products that collect and process Ancillary Data include:
            <ul className={ 'mt-1' }>
              <li>
                the Intramark browser plugin;
              </li>
              <li className={ 'pt-1' }>
                the Intramark desktop and mobile applications; and
              </li>
              <li className={ 'pt-1' }>
                Intramark Consumer Products.
              </li>
            </ul>
          </div>
          <div className={ 'mt-3' }>
            Through the Intramark browser plugin, the Ancillary Data we collect includes details about your
            login session, IP address, user-agent, and the web application name and website address, as well as
            other information that is not personal in nature. In addition, as part of Ancillary Data, we may collect
            interaction data about your use of the Intramark browser plugin. We use the information collected through
            the Intramark browser plugin for security purposes and to provide features, such as to allow you to
            better manage your passwords for websites that you visit.
          </div>
          <div className={ 'mt-3' }>
            Some products and services may require, or may be enhanced by, your installation of on-premise software
            (e.g., client application). The on-premise software may collect data about the use and performance of
            the software, including IP address, username, and host name, which may be transmitted and used by
            Intramark to provide customer support, to diagnose issues with the on-premise software and/or related
            products and services, to improve our products and services, and/or for the purposes identified in the
            applicable agreement(s) between Intramark and the applicable customer.
          </div>
        </Col>
      </Row>
      <Row className={ 'justify-content-center mt-5' }>
        <Col sm={ 6 } className={ 'text-justify' }>
          <h6><strong>Personal Data Collected Through Our Website, Trainings or Events, and Other Engagement</strong>
          </h6>
          <div>
            As with most websites, whenever you visit an Intramark website, take an Intramark training course,
            engage with any Intramark communities, or when you interact with Intramark promotional or other
            informational content, Intramark may receive both Personal Data about you from information-gathering
            tools and passive information collection on our websites, including Personal Data from our websites
            you visit or emails we send. This information collection typically includes information such as cookies,
            beacons, demographic information, company and role details, market research and publicly-available
            information, IP address (which may provide information about your location, but not your precise
            geolocation), device and browser details, usage information, timestamps, pages viewed, searches,
            interaction with and action taken by you on our websites or content (such as clicks on links and
            interaction with a webpage), as well as other non-Personal Data.
          </div>
        </Col>
      </Row>
    </>
  )
}