import React from 'react';
import './App.scss';
import { Footer } from './components/Footer/Footer';
import { AboutScreen } from './screens/AboutScreen/AboutScreen';
import { AgreementsScreen } from './screens/AgreementsScreen/AgreementsScreen';
import { ContactUsScreen } from './screens/ContactUsScreen/ContactUsScreen';
import { CopyrightScreen } from './screens/CopyrightScreen/CopyrightScreen';
import { DataProcessingAgreementScreen } from './screens/DataProcessingAgreementScreen/DataProcessingAgreementScreen';
import { HomeScreen } from './screens/HomeScreen/HomeScreen';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  MasterSubscriptionAgreementScreen
} from './screens/MasterSubscriptionAgreementScreen/MasterSubscriptionAgreementScreen';
import { MicroELearningScreen } from './screens/MicroELearningScreen/MicroELearningScreen';
import { PeopleServiceScreen } from './screens/PeopleServiceScreen/PeopleServiceScreen';
import { PrivacyScreen } from './screens/PrivacyScreen/PrivacyScreen';
import { PurposeScreen } from './screens/PurposeScreen/PurposeScreen';
import { ScheduleMeetingScreen } from './screens/ScheduleMeetingScreen/ScheduleMeetingScreen';
import { SecurityScreen } from './screens/SecurityScreen/SecurityScreen';
import { SubProcessorsScreen } from './screens/SubProcessorsScreen/SubProcessorsScreen';
import { TermsScreen } from './screens/TermsScreen/TermsScreen';
import { Unsubscribe } from './screens/UnsubscribeScreen/Unsubscribe';
import { VerificationProcessScreen } from './screens/VerificationProcessScreen/VerificationProcessScreen';
import { VibeInstallationScreen } from './screens/VibeInstallationScreen/VibeInstallationScreen';
import { VibeScreen } from './screens/VibeScreen/VibeScreen';

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <Routes location={location}>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/purpose" element={<PurposeScreen />} />
        <Route path="/about" element={<AboutScreen />} />
        <Route path="/vibe" element={<VibeScreen />} />
        <Route path="/micro-elearning" element={<MicroELearningScreen />} />
        <Route path="/people-service" element={<PeopleServiceScreen />} />
        <Route path="/privacy-policy" element={<PrivacyScreen />} />
        <Route path="/terms" element={<TermsScreen />} />
        <Route path="/contact" element={<ContactUsScreen />} />
        <Route path="/schedule" element={<ScheduleMeetingScreen />} />
        <Route path="/security" element={<SecurityScreen />} />
        <Route path="/agreements" element={<AgreementsScreen />} />
        <Route path="/copyright" element={<CopyrightScreen />} />
        <Route path="/dpa" element={<DataProcessingAgreementScreen />} />
        <Route path="/msa" element={<MasterSubscriptionAgreementScreen />} />
        <Route path="/verification-process" element={<VerificationProcessScreen />} />
        <Route path="/sub-processors" element={<SubProcessorsScreen />} />
        <Route path="/app-installation" element={<VibeInstallationScreen />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
      </Routes>
      {!location.pathname.includes('unsubscribe') && (
        <Footer />
      )}
    </div>
  );
}

export default App;
