import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const SecurityPosture: React.FC = () => {
  return (
    <Row className={ 'justify-content-center mt-5' }>
      <Col sm={ 6 } className={ 'text-justify' }>
        <h6><strong>VI. Intramark’s Security Posture & Measures Taken</strong></h6>
        <div>
          Security is a critical priority for Intramark. We maintain a comprehensive, written information security
          program that contains industry-standard administrative, technical, and physical safeguards designed to prevent
          unauthorized access to Personal Data.
        </div>
        <div className={ 'mt-3' }>
          However, no security system is perfect, and due to the inherent nature of the Internet, we cannot guarantee
          that data, including Personal Data, is absolutely safe from intrusion or other unauthorized access by others.
          You are responsible for protecting your password(s) and other authentication factors, as well as maintaining
          the security of your devices.
        </div>
        <div className={ 'mt-3' }>
          If you use the Intramark online service via a subscription purchased for you by an Intramark customer, then
          that customer is responsible for configuring your instance appropriately. Additional information about
          security settings and configurations can be found in the documentation related to our online service,
          including the Security and Data Privacy documentation, which is available at
          <a href={'/agreements'} className={'ms-1'}>https://www.intramark.io/agreements.</a>
        </div>
      </Col>
    </Row>
  )
}