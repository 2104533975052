import React from 'react';
import { Badge, Col, Container, Row, Table } from 'react-bootstrap';
import { Header } from '../../components/Header/Header';

export const MasterSubscriptionAgreementScreen: React.FC = () => {
  return (
    <section>
      <Header/>
      <Container className={ 'pt-4 pb-5' }>
        <Row className={ 'justify-content-center' }>
          <Col sm={ 6 } className={ 'text-start' }>
            <div className={ 'mt-4' }>
              <h3><strong>Intramark<span className={ 'font-primary' }>^</span> Master Subscription Agreement</strong>
              </h3>
            </div>
            <div>
              <Badge bg={ 'light' } text={ 'secondary' }>Last Updated: February 15, 2023</Badge>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <div>
              THIS MASTER SERVICE AGREEMENT (“AGREEMENT”) BETWEEN THE INTRAMARK LEGAL ENTITY SIGNING AN ORDER FORM AND
              ITS AFFILIATES (COLLECTIVELY, “INTRAMARK”, “COMPANY”, “WE” OR “US”) AND THE INDIVIDUAL OR LEGAL ENTITY
              LICENSING THE SERVICES UNDER THIS AGREEMENT AND/OR UNDER AN APPLICABLE ORDER FORM (“CUSTOMER” OR “YOU” AND
              TOGETHER WITH INTRAMARK, THE “PARTIES”) GOVERNS CUSTOMER’S ACCESS AND USE OF THE SERVICES.
              BY ACCEPTING THIS AGREEMENT WHILE EXECUTING AN ORDER FORM THAT REFERENCES THIS AGREEMENT, CUSTOMER AGREES
              TO THE TERMS OF THIS AGREEMENT. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER
              LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE
              TERMS AND CONDITIONS, IN WHICH CASE THE TERMS “CUSTOMER” “YOU” OR “YOUR” SHALL REFER TO SUCH ENTITY AND
              ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS,
              YOU MUST NOT ACCEPT THIS AGREEMENT AND SHALL NOT BE PERMITTED TO USE THE SERVICES.
              IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, PLEASE DO NOT CONNECT, ACCESS, OR USE THE SERVICES IN
              ANY MANNER. ANY SOFTWARE PROVIDED IN CONNECTION WITH THE SERVICES, IS BEING LICENSED AND NOT SOLD TO YOU.
              BY ACCEPTING THIS TERMS OF THIS AGREEMENT, YOU REPRESENT AND WARRANT THAT ANY AND ALL INFORMATION YOU
              PROVIDE US THROUGH THE SERVICES IS TRUE, ACCURATE AND COMPLETE. THE PROVISION OF FALSE OR FRAUDULENT
              INFORMATION IS STRICTLY PROHIBITED.
              This Agreement is effective between Customer and Intramark as of the date of Customer’s accepting this
              Agreement (the “Effective Date”).
            </div>
            <div className={ 'mt-3' }>
              <strong>Preamble</strong> Intramark has developed, and owns digital platforms that collect feedback from
              customer employees, among other services; Such solutions are provided to customers on a Software as a
              Service (SaaS) basis; and
              This Agreement governs the Parties’ relation in connection with Customer’s subscription, as well as its
              End User’s (as defined below), access to, and usage of the Service (as further defined below).
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>1. General</strong></h6>
            <div className={ 'text-justify' }>
              Customer will subscribe to the Intramark Service by executing an order form which shall define: (i) the
              subscription term; (ii) applicable Intramark’s solution to which Customer subscribes on web, mobile or
              desktop – “the Subscription Services”, including its modules and features and the digital
              platform/application/devices for which the Subscription Services will be deployed; (iii) the consideration
              to be paid by Customer; (iv) where applicable – certain usage limits, such as the number of End Users (as
              defined below) to whom the Service can be made available on a monthly basis; (v) where applicable – the
              provision of Professional Services (as defined below); and (vi) any other subscription terms that may be
              agreed upon by the Parties (“Order Form(s)“). Upon expiration of an applicable subscription period, the
              applicable Order Form may be renewed and/or replaced by a new Order Form subject to the terms defined
              therein.
              <br/> <br/>
              Intramark’s Subscription Services and/or Professional Services as specified and defined in an applicable
              Order Form shall be referred to as the “Service”.
              <br/> <br/>
              Each Order Form executed by the Parties is governed by this Agreement and is subject thereto.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>2. Right to Use the Service.</strong></h6>
            <div className={ 'text-justify' }>
              <ol type={ 'i' }>
                <li className={ 'pt-4' }>
                  <strong>Right to Use the Service:</strong> Subject to the terms and conditions of this Agreement and
                  of an applicable Order Form then in force, Intramark grants to Customer a non-exclusive,
                  non-transferable, non-sub-licensable, limited right to access and use the Service during the
                  subscription term, and in accordance with the usage terms set forth herein and in the applicable Order
                  Form.
                  <br/><br/>
                  For the purpose of this Agreement or any applicable Order Form, “End User(s)” means individual(s) for
                  whom the Subscription Services load.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Usage Restrictions:</strong>Other than the rights expressly specified in this Agreement and in
                  an applicable Order Form, no other right or interest whatsoever is granted to Customer in connection
                  with the Service or to the solutions to which it provides access. Without limiting the foregoing,
                  Customer may not: (i) use the Service for purposes other than the purposes for which it is intended as
                  defined in this Agreement and/or the applicable Order Form; (ii); rent, lease, lend, sell, sublicense,
                  assign, distribute, or transfer in whole or in part the right to use the Service or any part thereof;
                  (iii) bypass or breach any security device or protection used by the Service; (iv) input, upload,
                  transmit, or otherwise provide to or through the Service any information or materials that are
                  unlawful or injurious, or that contain, transmit, or activate any harmful code; (v) use the Service in
                  any illegal manner or in any way that infringes the right of any third party. In addition, in the
                  event the Order Form limits the usage to a certain number of End Users, Customer shall ensure that the
                  quantity of End Users who may access and use the Service in any given calendar month will not exceed
                  the quantity set forth in the Order Form.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>3. Professional Services.</strong></h6>
            <div className={ 'text-justify' }>
              If an applicable Order Form includes the provision of certain professional services (which typically
              include implementation, training with respect of the use of the Service, or assistance in creating Outputs
              (as defined below)) (<strong>“Professional Services“</strong>), then such services shall be provided in
              accordance with the
              Statement of Work to be attached to the Order Form (<strong>“SOW“</strong>).
              <br/><br/>
              The Professional Services shall be provided remotely (and not at Customer’s site), unless otherwise agreed
              in writing between the Parties. If agreed, the Professional Services can be performed by a third party.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>4. Consideration; Taxes.</strong></h6>
            <div className={ 'text-justify' }>
              <ol type={ 'i' }>
                <li className={ 'pt-4' }>
                  <strong>Consideration:</strong> Customer will timely pay all fees for the Service (<strong>“Subscription
                  Fees“</strong>), as specified in an applicable Order Form, and all fees for the Professional Services
                  as set
                  forth in the applicable SOW, or in the Order Form, as applicable.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Subscription Fees:</strong> The Subscription Fees constitute the consideration for the
                  Intramark’s solution, i.e. the specific modules and features and the digital
                  platform/application/devices explicitly identified in the Order Form. Except as otherwise specified
                  herein or in an Order Form: (i) the fees for the Service are based on the Service purchased and not on
                  actual usage thereof, and fees paid are non-refundable; and (ii) Service ordered under an applicable
                  Order Form and their respective payment obligations are non-cancelable. Notwithstanding the
                  aforementioned, in an event of termination for cause according to Section 11.2. below, due to a
                  material breach by Intramark, the foregoing shall not apply to amounts paid for the Service in
                  advance, but which are unused on the date of termination (calculated on a pro-rata basis of the
                  balance period between the termination date and the original term of the applicable Order Form), which
                  may be refundable.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Invoicing:</strong> Customer is responsible for providing complete and accurate billing and
                  contact information to Intramark.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Taxes:</strong> Intramark’s fees are exclusive of any taxes, levies, duties or similar
                  governmental assessments of any nature, other charges, domestic or foreign imposed by any federal,
                  state, or local tax authority with respect thereto including, for example, value-added, sales, use or
                  withholding taxes, assessable by any jurisdiction whatsoever (collectively, “Taxes”). Customer is
                  responsible for paying all Taxes associated with Customer’s purchases hereunder. If Intramark has the
                  legal obligation to pay or collect Taxes for which Customer is responsible under this Section 4,
                  Intramark will invoice Customer and Customer will pay that amount unless Customer provides Intramark
                  with a valid tax exemption certificate authorized by the appropriate taxing authority. To the extent
                  Customer is required by law to withhold income-based taxes based upon the fees hereunder, Customer
                  will deduct such tax from the fees payable to Intramark and remit them to the appropriate government
                  authorities; provided that Customer sends Intramark a receipt showing the payment of such Tax, and
                  provides Intramark with reasonable support and with sufficient evidence to enable Intramark to obtain
                  any credits available to it. For clarity, Intramark is solely responsible for Taxes assessable against
                  it based on its income, property and employees.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>5. Privacy and Data Protection; Security.</strong></h6>
            <div className={ 'text-justify' }>
              <ol type={ 'i' }>
                <li className={ 'pt-4' }>
                  <strong>Collection and Storage by Intramark:</strong> Solely to the extent necessary for the provision
                  of the Service to the Customer (as described in detail in the DPA referred below), Intramark (in its
                  capacity as Data Processor) may from time to time be provided with, or have access to, information of
                  Customer (in its capacity as Data Controller), which may qualify as Personal Data (as these terms are
                  defined in the DPA).
                  For the purpose of this MSA and any applicable Order Form, Parties’ rights and obligations with
                  respect to the data processing activities shall be subject to the Data Processing Agreement (“DPA”)
                  available at: <a href={ 'https://www.intramark.io/dpa' }>https://www.intramark.io/dpa</a>
                </li>
                <li className={ 'pt-4' }>
                  <strong>Customer’s Undertakings:</strong> Customer shall be solely responsible for obtaining all
                  consents and authorizations as may be required by any applicable law, for the collection, storage and
                  processing of information and/or sensitive information by Intramark according to Customer’s
                  instructions.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Security:</strong> . Intramark complies with security standards, such as encryption of data in
                  motion over public networks. In addition, Intramark shall have in place and shall comply with
                  documented written policies and procedures, periodically reviewed, covering the administrative,
                  physical and technical safeguards in place and relevant to the access, use, loss, alteration,
                  disclosure, storage, destruction and control of information.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>6. Proprietary Rights.</strong></h6>
            <div className={ 'text-justify' }>
              <ol type={ 'i' }>
                <li className={ 'pt-4' }>
                  <strong>Intramark’s Rights:</strong> Intramark owns and shall retain all right, title, and interest,
                  including Intellectual Property Rights (as defined below), in and to the Service and the solutions
                  provided thereby, and all the underlying software and technology, all as may be updated, improved,
                  modified or enhanced from time to time; and further – in and to the brand names, logos and trademarks
                  related to the foregoing. In addition, subject to Customer’s ownership rights pursuant to Section 6.2
                  below, Intramark owns and shall retain all rights, including Intellectual Property Rights in and to
                  the Outputs of the Service, generated by Intramark, including the content created by Intramark and the
                  default designs and “look and feel” thereof.
                  <br/><br/>
                  For the purpose of this Agreement, <strong>“Intellectual Property Rights”</strong> means any and all
                  intellectual
                  property rights, whether registered or not, worldwide including, without limitation, all the
                  following: (i) copyrights, including moral rights, registrations and applications for registration
                  thereof; (ii) computer software programs, data and documentation; (iii) patents, patent applications
                  and all related continuations, divisional, reissue, design patents, applications and registrations
                  thereof, certificates of inventions; and (iv) trademarks, trademark applications, domain names, trade
                  secrets and Confidential Information (as defined below).
                  <br/><br/>
                  <strong>“Outputs”</strong> shall mean the interactive on-line guidance indicators deployed by the
                  Service, to provide
                  guidance and assistance to End Users in acting and reacting (including by progressing through a
                  process) within an applicable digital platform or application.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Customer’s Ownership:</strong> Customer owns and shall retain all right title and interest in
                  and to the Content (as defined below) which is (i) provided by Customer to Intramark for the purpose
                  of the provision of the Service and/or the Professional Services; (ii) created by Customer using the
                  Service; and/or (iii) to the extent applicable, created by Intramark specifically for Customer under
                  the provision of Professional Services, and which incorporates or is based on Customer’s copyrighted
                  work and/or Customer’s Confidential Information or other Customer Content according to sub-sections
                  (i) and (ii) above (<strong>“Customer Content“</strong>). Customer Content specifically excludes
                  Content provided by
                  Intramark which does not use Customer Content, the Outputs’ “look and feel”, and Intramark’s
                  Confidential Information and Intellectual Property.
                  <br/><br/>
                  <strong>“Content”</strong> means content such as text, data, images, photographs, video, audio, and
                  similar types of
                  content used in, or for creating, Outputs.
                </li>
                <li className={ 'pt-4' }>
                  Customer hereby grants to Intramark a limited, non-exclusive, non-transferable (except in connection
                  with the permitted assignment of this Agreement), and royalty-free license to use the Customer Content
                  and its affiliates solely for the purpose of providing the Service to Customer pursuant to this
                  Agreement and the applicable Order Form.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Feedback:</strong> Intramark may, at its discretion and for any purpose, freely use, modify,
                  and incorporate
                  into its Service any feedback, comments, or suggestions provided by Customer or End Users (other than
                  Customer Content), if any, without any additional obligation of Intramark to Customer or the
                  applicable End Users.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>7. Warranty.</strong></h6>
            <div className={ 'text-justify' }>
              <ol type={ 'i' }>
                <li className={ 'pt-4' }>
                  <strong>Representations:</strong> Intramark represents and warrants to Customer that (i) during the
                  term of each applicable Order Form, the Service will substantially conform to the specifications as
                  set forth in such Order Form; and (ii) the Professional Services performed by or on behalf of
                  Intramark under this Agreement will be performed in a professional and workmanlike manner and by
                  personnel that has the necessary skills, training and background to perform such Services.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Exclusive Warranty:</strong> THE WARRANTIES CONTAINED IN THIS SECTION 7 ARE EXCLUSIVE, AND
                  ARE IN LIEU OF ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED
                  WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR ARISING BY A COURSE OF
                  DEALING OR USAGE OF TRADE. THIS SECTION DOES NOT LIMIT, AND IS WITHOUT PREJUDICE TO, THE PROVISIONS OF
                  SECTION 10 BELOW.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>8. Indemnification by Intramark.</strong></h6>
            <div className={ 'text-justify' }>
              <ol type={ 'i' }>
                <li className={ 'pt-4' }>
                  <strong>Indemnification Obligation:</strong> Intramark will defend Customer from and against any claim
                  by a third party against Customer to the extent the claim is based on an allegation that the Service
                  or the solution provided by it, infringes upon, or misappropriates, any Intellectual Property Rights
                  of a third party (<strong>“Infringement Claim“</strong>), and shall indemnify Customer against all
                  liabilities,
                  damages, costs (including settlement costs and reasonable attorneys’ fees) awarded by a competent
                  court, arbitrator/s, or in a settlement, as a result of such claim by a third party; provided that (i)
                  Customer has notified Intramark promptly in writing of such claim; (ii) Customer has provided
                  Intramark with the authority to control and handle the claim including the defense and settlement of
                  such claim; and (iii) Customer provides to Intramark all information and assistance (at Intramark’s
                  expense) as may be required for that purpose.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Exclusions:</strong> In no event will Intramark have any obligation or liability under this
                  Section 8 arising from: (i) use of any Service in a modified form or in combination with materials not
                  furnished by Intramark; (ii) any Customer Content; (iii) any failure by Customer to comply with
                  Customer’s responsibilities under this Agreement; and (iv) use by Intramark of any equipment provided
                  by Customer and per Customer’s instructions, for the provision of the Professional Services.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Remedial Actions:</strong> In the event that the Service or any part thereof is likely to, in
                  Intramark’s sole opinion, or does become the subject of an Infringement Claim, Intramark may, at its
                  option and expense: (i) procure for Customer the right to continue using the Service (including the
                  allegedly infringing portion/item); (ii) substitute a functionally equivalent non-infringing
                  replacement for such allegedly infringing portion of the Service or otherwise modify it to make it
                  non-infringing and functionally equivalent; or (iii) terminate the Agreement and any outstanding Order
                  Form and refund to Customer fees paid to Intramark for the infringing items in an amount prorated to
                  reflect the period of time between the date Customer was unable to us
                </li>
                <li className={ 'pt-4' }>
                  <strong>Sole Remedy:</strong> Without derogating from the provisions of Section 10 below, this Section
                  8 sets forth the exclusive and entire remedy of Customer with respect to any Infringement Claims.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>9. Customer’s Responsibilities.</strong></h6>
            <div className={ 'text-justify' }>
              In addition to any other undertaking or responsibility of Customer as set forth in this Agreement, any
              applicable Order Form, SOW or an exhibit hereto or thereto, Customer shall be solely responsible and
              liable for, and in connection with: (i) the manner in which Customer and the End Users use the Service
              (ii) Customer Content, including without limitation Customer’s rights to use such Content in connection
              with the Service; (iii) information, data or other Content provided by End Users; and/or (iv) compliance
              by Customer with any and all applicable laws and with third parties’ rights in connection with the
              foregoing.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>10. Limitation of Liability.</strong></h6>
            <div className={ 'text-justify' }>
              <ol type={ 'i' }>
                <li className={ 'pt-4' }>
                  <strong>Limitation on Indirect Liability:</strong> TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT
                  SHALL EITHER PARTY BE LIABLE UNDER THIS AGREEMENT FOR (i) INDIRECT, SPECIAL, INCIDENTAL,
                  CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, OR (ii) FOR LOSS OF USE, BUSINESS, REVENUES, OR
                  PROFITS; IN EACH CASE, EVEN IF THE PARTY KNEW OR SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE AND
                  EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Limitation on Amount of Liability:</strong> TO THE FULLEST EXTENT PERMITTED BY LAW, AND OTHER
                  THAN IN THE EVENT OF WILLFUL MISCONDUCT OR GROSS NEGLIGENCE, BREACH OF ITS CONFIDENTIALITY
                  OBLIGATIONS, INTRAMARK’S LIABILITY IN CONNECTION WITH ITS IP INDEMNITY OBLIGATIONS UNDER SECTION 8,
                  CUSTOMER’S (A) BREACH OF SECTION 6 (PROPRIETARY RIGHTS) AND (B) INDEMNITY OBLIGATIONS UNDER SECTION
                  13.4 (EXPORT RESTRICTIONS) OR AS SET FORTH BELOW IN THIS SECTION 10.2, A PARTY’S AGGREGATE LIABILITY
                  UNDER EACH ORDER FORM SHALL NOT EXCEED THE AMOUNT PAID BY CUSTOMER FOR THE APPLICABLE ORDER FORM, AND
                  A PARTY’S AGGREGATE LIABILITY UNDER THIS AGREEMENT SHALL NOT EXCEED THE AMOUNT PAID BY CUSTOMER DURING
                  THE TWELVE (12) MONTHS PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY (<strong>“LIABILITY
                  CAP”</strong>).
                  <br/><br/>
                  NOTWITHSTANDING THE ABOVE, INTRAMARK’S LIABILITY FOR BREACH OF ITS PRIVACY AND DATA PROTECTION
                  OBLIGATIONS UNDER SECTION 5 – SHALL BE LIMITED TO THREE TIMES (3X) THE APPLICABLE LIABILITY CAP,
                  PROVIDED HOWEVER, THAT A FINE OR CHARGE SET BY A REGULATORY AUTHORITY ACCORDING TO LAW FOR, OR DUE
                  SOLELY TO, BREACH BY INTRAMARK OF DATA PROTECTION AND SECURITY OBLIGATIONS SHALL BE BORNE BY INTRAMARK
                  REGARDLESS OF THE CAP ON LIABILITY.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>11. Term, Termination and Suspension of Service.</strong></h6>
            <div className={ 'text-justify' }>
              <ol type={ 'i' }>
                <li className={ 'pt-4' }>
                  <strong>Term:</strong> This Agreement commences on the Effective Date and will remain in effect for so
                  long as Customer has an applicable Order Form in effect, unless otherwise terminated in accordance
                  with the provisions herein.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Termination:</strong> Either Intramark or Customer may terminate this Agreement and any Order
                  Form thereunder, if: (i) the other Party is in material breach of the Agreement and fails to cure that
                  breach within thirty (30) days after receipt of written notice; or (ii) the other Party ceases its
                  business operations or becomes subject to insolvency proceedings and the proceedings are not dismissed
                  within ninety (90) days.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Effect of Termination:</strong> In any event of termination of this Agreement by either Party:
                  <ol type={ 'a' }>
                    <li>
                      All rights granted hereunder shall immediately expire and any and all use and/or exploitation by
                      Customer and/or on its behalf of the Services, and any part thereof, shall immediately cease and
                      expire.
                    </li>
                    <li>
                      Intramark shall provide Customer access to its account, at no additional fees, for a period of
                      thirty (30) days following termination or expiration of the Agreement, so that Customer may export
                      any stored data.
                    </li>
                    <li>
                      Provisions contained in this Agreement that are expressed or by their sense and context are
                      intended to survive the termination of this Agreement shall so survive the termination, including
                      without limitation Section 4 (Consideration due for the period prior to termination), Section 5
                      (Privacy, with respect to the period prior to termination) and Sections 6 through 13 (inclusive).
                    </li>
                  </ol>
                </li>
                <li className={ 'pt-4' }>
                  <strong>Suspension of Service:</strong> Intramark reserves the right to monitor the use of the Service
                  for security and operational purposes. Intramark may suspend or otherwise deny Customer’s or any other
                  person’s access to or use of all or any part of the Services for security reasons if Intramark
                  believes, in its reasonable discretion, that any third party has gained unauthorized access to any
                  portion of the Subscription Services using any credential issued by Intramark to Customer or its End
                  Users.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>12. Confidentiality.</strong></h6>
            <div className={ 'text-justify' }>
              <ol type={ 'i' }>
                <li className={ 'pt-4' }>
                  For the purpose of this Agreement, <strong>“Confidential Information”</strong> means any non-public
                  information
                  disclosed by a Party (<strong>“Disclosing Party”</strong>) to the other Party (<strong>“Receiving
                  Party”</strong>), that is designated
                  in writing as confidential or if disclosed orally – is reduced to writing and titled as “confidential”
                  within 15 days following the disclosure and sharing with the Receiving Party, or that reasonably
                  should be understood to be confidential given the nature of the information and/or the circumstances
                  of disclosure. Confidential Information shall include, but is not limited to, technological
                  information such as know-how, software, data, programs, inventions, ideas, processes, formulas,
                  developments, designs, materials, business information such as marketing and selling, budgets, prices
                  and costs, information about the Disclosing Party’s employees, Affiliates, suppliers and customers,
                  and trade secrets. Confidential Information does not include information that is: (i) public knowledge
                  at the time of disclosure or thereafter becomes generally known other than through an act of breach or
                  negligence by the Receiving Party; (ii) already known by the Receiving Party prior to its receipt from
                  the Disclosing Party; (iii) independently developed at any time by the Receiving Party without use of
                  or reference to Confidential Information; (iv) rightfully obtained by the Receiving Party from other
                  unrestricted sources.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Protection of Confidential Information:</strong> All Confidential Information delivered, made
                  available or otherwise acquired pursuant to this Agreement shall (i) not be copied, distributed,
                  disseminated or made available in any way or form by Receiving Party without the prior written consent
                  of the Disclosing Party; (ii) be maintained in confidence using the same degree of care that the
                  Receiving Party takes to protect its own confidential information, but in no event less than
                  reasonable care; (iii) may only be disclosed to those employees, contractors and/or service providers
                  of Receiving Party who have a need to know in connection with purposes consistent with this Agreement,
                  and who are bound by a written obligation of confidentiality no less restrictive as those set forth
                  herein; and (iv) shall not be used by Receiving Party for any purpose, except for the purposes of this
                  Agreement, without the prior written consent of the Disclosing Party. For the avoidance of doubt,
                  Confidential Information including personal information collected through the use of the Services
                  shall be used, collected, retained, processed and deleted in accordance with the provisions of Section
                  5 above.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Compelled Disclosure:</strong> If the Receiving Party is compelled by law to disclose any
                  Confidential Information then, to the extent permitted by applicable law, the Receiving Party shall
                  (i) promptly, and prior to such disclosure, notify the Disclosing Party in writing of such requirement
                  so that the Disclosing Party can seek a protective order; and (ii) provide reasonable assistance to
                  the Disclosing Party, at the Disclosing Party’s sole cost and expense, in opposing such disclosure or
                  seeking a protective order or other limitations on disclosure.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Expiration:</strong> The provisions of this Section 12 shall survive the natural expiration or
                  termination of this Agreement for any reason for a period of three (3) years or for seven (7) years
                  following their disclosure, whichever is earlier
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-4' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>13. Miscellaneous.</strong></h6>
            <div className={ 'text-justify' }>
              <ol type={ 'i' }>
                <li className={ 'pt-4' }>
                  <strong>Entire Agreement:</strong> This Agreement, including all exhibits hereto and all applicable
                  Order Forms, constitute the entire agreement between Customer and Intramark with respect to the
                  subject matter of this Agreement and supersede and replace any prior or contemporaneous understandings
                  and agreements, whether written or oral, with respect to the subject matter of this Agreement,
                  including previous non-disclosure agreements between the Parties.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Assignment; Change of Control:</strong>
                  <ol type={ 'a' }>
                    <li>
                      Neither Party may assign any of its rights or obligations hereunder, whether by operation of law
                      or otherwise, without the other Party’s prior written consent, not to be unreasonably withheld;
                      such consent shall not, however, be required, in connection with an assignment to an affiliate or
                      a successor in interest in connection with any merger, consolidation, reorganization or
                      restructuring, or the sale of substantially all of a Party’s assets as long as such successor or
                      assignee of this Agreement agrees in writing to be bound by this Agreement and by the terms of any
                      outstanding Order Form.
                    </li>
                    <li>
                      In any event of an assignment by a Party to this Agreement, or of any other form of Change of
                      Control, the assigning Party or the Party undergoing a Change of control, as applicable, will
                      notify the other Party in writing immediately upon the consummation of such event (<strong>“Assignment
                      Notice“</strong>).
                      <br/><br/>
                      For the purpose hereof a <strong>“Change of Control”</strong> means that a Party has undergone a
                      change of control
                      transaction, such that the holders of such Party’s outstanding stock capital, as of immediately
                      prior to such transaction, owning less than 50% of the voting power of the surviving or resulting
                      entity’s outstanding stock capital immediately after such transaction.
                    </li>
                  </ol>
                </li>
                <li className={ 'pt-4' }>
                  <strong>Governing Law and Jurisdiction:</strong> The law that will apply in any dispute or lawsuit
                  arising out of or in connection with this Agreement, including its exhibits, the Order Forms and SOWs,
                  and the courts that have jurisdiction over any such dispute or lawsuit, depend on the Intramark entity
                  entering into this Agreement as detailed below.
                  <Table bordered className={ 'mt-3 justify-content-start small' }>
                    <thead className={ 'font-bold' }>
                    <tr>
                      <td>Intramark Entity</td>
                      <td>Governing Law is:</td>
                      <td>Courts with exclusive jurisdiction are:</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Intramark eXperience LLC</td>
                      <td>State of California</td>
                      <td>San Francisco, California</td>
                    </tr>
                    <tr>
                      <td>Intramark Services LLC</td>
                      <td>State of California</td>
                      <td>San Francisco, California</td>
                    </tr>
                    </tbody>
                  </Table>
                </li>
                <li className={ 'pt-4' }>
                  <strong>Export Restrictions:</strong> Customer acknowledges and agrees that its use of the Services,
                  and the use of the Services by End Users, is subject to compliance with United States and other
                  applicable country export control and trade sanctions laws and regulations, including, without
                  limitations the regulations promulgated by the U.S. Department of Commerce and the U.S. Department of
                  the Treasury (“Export Control and Sanctions Laws”). Customer shall be solely responsible for complying
                  with the Export Control and Sanctions Laws and monitoring any modifications to them. Customer
                  represents and warrants that (i) Customer is not located in, operating from, established under the
                  laws of, or otherwise ordinarily resident in Cuba, Iran, North Korea, Syria, or the Crimea region of
                  Ukraine (the “Sanctioned Countries”); (ii) Customer is not identified on any list of prohibited or
                  restricted parties (including, without limitation, the U.S. Treasury Department’s List of Specially
                  Designated Nationals, the U.S. Department of Commerce’s Denied Persons List, Entity List, and
                  Unverified List, and the U.S. Department of State’s proliferation-related lists) (a “Prohibited
                  Person”); (iii) Customer is not otherwise the target of U.S. sanctions or U.S. trade restrictions; and
                  (iv) Customer will not export, reexport, transfer, or allow access to the Services to any parties,
                  including End Users, in Sanctioned Countries or Prohibited Persons or otherwise in violation of Export
                  Control and Sanctions Laws.
                  <br/><br/>
                  Notwithstanding anything in this Agreement to the contrary, in the event that Customer fails to comply
                  with any provision of this section of the Agreement or violates any Export Control and Sanctions Laws
                  in connection with the Services, Intramark shall have the right unilaterally to terminate this
                  Agreement immediately or to take other appropriate action in accordance with the terms of this
                  Agreement and as required by U.S. law. Further, Customer will fully indemnify and hold harmless
                  Intramark and its representatives against any claim, action, damages, fines, liabilities, expenses
                  (including attorney’s fees and expenses), and costs relating in any way to Customer’s noncompliance
                  with this section, including Customer’s violation or alleged violation of any Export Control and
                  Sanctions Laws.
                </li>
                <li className={ 'pt-4' }>
                  <strong>Other Provisions:</strong> If any provision of this Agreement is held by a court of competent
                  jurisdiction to be contrary to law, the provision will be deemed null and void, and the remaining
                  provisions of this Agreement will remain in effect. Any notice required or permitted to be given by
                  either Party under this Agreement shall be in writing and may be delivered by courier, sent by
                  registered letter, and shall be effective upon receipt or, if sent by email, upon proof of being sent.
                  Any notice to either Party shall be sent to the contact information listed in the applicable Order
                  Form. A copy of notices to Intramark shall also be sent to <a
                  href={ 'mailTo:legal@intramark.io' }>legal@intramark.io</a>
                  <br/><br/>
                  No failure or delay by any Party at any time to enforce one or more of the terms, conditions or
                  obligations of this Agreement will (i) constitute waiver of such term, condition or obligation; (ii)
                  preclude such Party from requiring performance by the other Party at any later time; or (iii) be
                  deemed to be a waiver of any other subsequent term, condition or obligation, whether of like or
                  different nature.
                  <br/><br/>
                  In any event of a conflict or inconsistency between the terms herein and the terms of the Order Form,
                  the terms of the Order Form shall prevail. Any additional or conflicting terms contained in purchase
                  orders issued by Customer with respect to the subject matter hereof are hereby expressly rejected and
                  shall have no force or effect on the terms of this Agreement or any Order Form.
                  <br/><br/>
                  The Parties are independent contractors. This Agreement does not create a partnership, franchise,
                  joint venture, agency, and fiduciary or employment relationship between the Parties.
                  <br/><br/>
                  Except for payment obligations, neither Intramark nor Customer will be liable for inadequate
                  performance to the extent caused by a condition that was beyond the party’s reasonable control (for
                  example, natural disaster, act of war or terrorism, riot, labor condition, governmental action, and
                  Internet disturbance). Except to the extent required by applicable law, there are no third-party
                  beneficiaries under this Agreement.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
