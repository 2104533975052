import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const CaliforniaResidents: React.FC = () => {
  return (
    <Row className={ 'justify-content-center mt-5' }>
      <Col sm={ 6 } className={ 'text-justify' }>
        <h6><strong>XI. Information for California Residents / Your California Privacy Rights</strong></h6>
        <div>
          Under the California Consumer Privacy Act of 2018 (“CCPA”), California residents have certain rights to
          understand and request that we disclose details about how we handle your Personal Data. If you would like to
          learn about our process for verifying requests, including the details that you must provide to us to verify
          your request, visit <a href={ '/verification-process' }>Verification Process for Individual Rights
          Requests</a>. To learn more about how we collect, use, disclose, and share your Personal Data, please see
          below.
        </div>
        <div className={ 'mt-3' }>
          <strong>Categories of Personal Data Collected</strong>
          <div>
            In the preceding 12 months, we have collected the following categories of Personal Data about California
            consumers. We may collect this Personal Data directly from you, from third parties, and from your
            interactions with us. For additional detail about the Personal Data that we collect and the sources from
            which we collect this Personal Data, please review Section III above. The Personal Data categories are:
          </div>
          <div className={ 'mt-3' }>
            <ul>
              <li>
                identifiers, such as name, email address, address, and phone number;
              </li>
              <li className={ 'pt-1' }>
                categories of Personal Data described in subdivision (e) of Section 1798.80, such as address, telephone
                number, employment, and employment history;
              </li>
              <li className={ 'pt-1' }>
                commercial information, such as records of products or services purchased and other transactional data;
              </li>
              <li className={ 'pt-1' }>
                Internet or other network or device activity details, such as technical data about your use of our
                website, products and services;
              </li>
              <li className={ 'pt-1' }>
                geolocation data, such as your approximate location based on IP address;
              </li>
              <li className={ 'pt-1' }>
                audio, electronic, or visual data, such as part of a photo or recording for an Intramark in-person or
                virtual event;
              </li>
              <li className={ 'pt-1' }>
                professional or employment-related information, such as your employer and job role;
              </li>
              <li className={ 'pt-1' }>
                education information and details such as education history, certifications and qualifications;
              </li>
              <li className={ 'pt-1' }>
                any other information you provide to Intramark in connection with a job application, such as information
                contained in a cover letter, information disclosed in an interview, or other information you volunteer
                during the application process; and
              </li>
              <li className={ 'pt-1' }>
                inferences drawn from any of the above information.
              </li>
            </ul>
          </div>
          <div className={ 'mt-3' }>
            We may retain this Personal Data for as long as is needed for the purpose(s) for which it was collected and
            no longer than is relevant and reasonably necessary. Our retention periods vary based on business, legal and
            regulatory needs. We securely retain records of data requests for at least 24 months as required under the
            CCPA. For further information on our retention criteria see Section IX above.
          </div>
        </div>
        <div className={ 'mt-3' }>
          <h6><strong>Business and Commercial Purposes for Collection; Disclosures for a Business Purpose</strong></h6>
          <div>
            We may collect all of the above categories of Personal Data to run our business and carry out our day-to-day
            activities, as described above in Section IV. We have disclosed each of these categories of Personal Data
            with our service providers for various business purposes, as described above in Section V, in the preceding
            12 months.
          </div>
          <div className={ 'mt-3' }>
            We collect and process Personal Data related to job applicants to recruit and hire, assess and evaluate a
            job applicant’s skills and qualifications, comply with legal obligations, and send information about
            positions at Intramark that may be of interest to individuals.
          </div>
        </div>
        <div className={ 'mt-3' }>
          <h6><strong>Categories of Personal Data Sold or Shared for Cross-Context Behavioral Advertising</strong></h6>
          <div>
            In the preceding 12 months, we have disclosed the above categories of Personal Data to third-party
            advertising partners, such as in connection with our use of tracking technologies for cross-context
            behavioral advertising or by providing lists of email addresses for potential customers, so that we can
            reach you across the web with advertisements for our products and services. This may be considered “sharing”
            or a “sale” under the CCPA. You can read more about our sharing and sales activities above in Section III
            and Section V. Intramark does not have actual knowledge that it sells or shares the personal data of
            consumers under 16 years of age.
          </div>
        </div>
        <div className={ 'mt-3' }>
          <h6><strong>Categories of Personal Data Sold or Shared for Job Applicants</strong></h6>
          <div>
            Intramark has not sold or shared any Personal Data relating to job applicants, in the 12 months preceding
            the effective date of this Privacy Policy.
          </div>
        </div>
        <div className={ 'mt-3' }>
          <h6><strong>Sensitive Personal Data</strong></h6>
          <div>
            In addition to the categories of Personal Data listed above, we may collect certain categories of Sensitive
            Personal Data from you as that term is defined under CCPA, if you choose to provide it. In the preceding 12
            months, we may have collected the following categories of Sensitive Personal Data from California consumers:
          </div>
          <div className={ 'mt-3' }>
            <ul>
              <li>Social Security Number, driver’s license, state identification card, or passport number;</li>
              <li className={ 'pt-1' }>
                account log-In, financial account, debit card, or credit card number in
                combination with any required security or access code, password, or credentials allowing access to an
                account;
              </li>
              <li className={ 'pt-1' }>precise geolocation;</li>
              <li className={ 'pt-1' }>
                racial or ethnic origin, religious or philosophical beliefs, or union membership;
              </li>
              <li className={ 'pt-1' }>
                contents of your email and text messages, unless we are the intended recipient of
                the communication;
              </li>
              <li className={ 'pt-1' }>biometric information for the purpose of unique identification;</li>
              <li className={ 'pt-1' }>health information; and</li>
              <li className={ 'pt-1' }>sex life or sexual orientation information.</li>
            </ul>
          </div>
        </div>
        <div className={ 'mt-3' }>
          <h6><strong>Categories of Sensitive Personal Data Disclosed</strong></h6>
          <div>
            In the preceding 12 months Intramark has not sold or shared any Sensitive Personal Data; however, we may
            have disclosed your Sensitive Personal Data to service providers for business purposes as further described
            in Section V above and as set forth below.
          </div>
          <div className={ 'mt-3' }>
            <strong>Business Purposes For Which Sensitive Personal Data will be Used or Disclosed</strong>. We may
            collect the categories of Sensitive Personal Data listed for processing job applications and the purposes as
            further described in Section IV above, and to further our legitimate business purposes as outlined under the
            CCPA:
          </div>
          <div className={ 'mt-3' }>
            <ul>
              <li>
                performing services, including maintaining or servicing accounts;
              </li>
              <li className={ 'pt-1' }>
                detection and prevention of security incidents;
              </li>
              <li className={ 'pt-1' }>
                protecting against malicious, deceptive, fraudulent or illegal actions and prosecuting those
                responsible;
              </li>
              <li className={ 'pt-1' }>
                auditing related to consumer interactions;
              </li>
              <li className={ 'pt-1' }>
                short-term, transient use;
              </li>
              <li className={ 'pt-1' }>
                quality and safety maintenance or verification;
              </li>
              <li className={ 'pt-1' }>
                internal research for technological development; and
              </li>
              <li className={ 'pt-1' }>
                debugging to identify and repair functionality.
              </li>
            </ul>
          </div>
        </div>
        <div className={ 'mt-3' }>
          <h6><strong>Your Rights</strong></h6>
          <div>The CCPA gives you certain rights regarding the Personal Data we collect about you:</div>
          <div className={ 'mt-3' }>
            <i>Right to Know About Personal Data Collected, Disclosed, or Sold</i>. You have the right to request to
            know what Personal Data we collect, use, disclose, share and sell about you.
          </div>
          <div className={ 'mt-3' }>
            <i>Right to Request Deletion of Personal Data</i>. You have the right to request the deletion of your
            Personal Data collected or maintained by us as a business.
          </div>
          <div className={ 'mt-3' }>
            <i>Right to Opt-Out of the Sale or Sharing of Personal Data</i>. You have the right to opt-out of the sale
            of your Personal Data by us as a business. Intramark shares Personal Data as described above, which may be
            considered a “sale” of Personal Data under the CCPA.
          </div>
          <div className={ 'mt-3' }>
            You may opt out by completing our online form and selecting your preferences on that page. You may also opt
            out by broadcasting an opt-out preference signal like the Global Privacy Control (GPC), but please note that
            this signal will be linked to your browser only. If you wish to learn more about the GPC and how to use a
            browser or browser extension incorporating the GPC signal, you can visit the GPC
            website <a href={ 'https://globalprivacycontrol.org/' }>here</a>.
          </div>
          <div className={ 'mt-3' }>
            <i>Right to Limit the Use and Disclosure of Sensitive Personal Data</i>. In some instances, we may use or
            disclose your Sensitive Personal Data for the legitimate business purposes as outlined under the CCPA, and
            for any other purposes as set forth in Section IV, above. If we ever use or disclose your Sensitive Personal
            Data for a reason other than the legitimate business purposes as outlined under the CCPA and for any other
            purposes other than those described in Section IV, we will update this Privacy Policy and provide you with
            instructions to limit the use and disclosure of your Sensitive Personal Data.
          </div>
          <div className={ 'mt-3' }>
            <i>Right to Correct Inaccurate Personal Data</i>. You have the right to request the correction of your
            Personal Data if it is inaccurate and you may submit a request as further described below.
          </div>
          <div className={ 'mt-3' }>
            <i>Right to Non-Discrimination for the Exercise of Your Privacy Rights</i>. You have the right not to
            receive discriminatory treatment by us for the exercise of your privacy rights conferred by the CCPA.
          </div>
          <div className={ 'mt-3' }>
            <i>Authorized Agent</i>. You may designate an authorized agent to make a request under the CCPA on your
            behalf. We may require the agent to demonstrate proof of their authorization by providing us with a signed
            permission from you or a copy of your power-of-attorney document granting that right. In the case of the
            former, we may still request that you verify your own identity as described above or directly confirm that
            you have provided such permission.
          </div>
          <div className={ 'mt-3' }>
            <i>Financial Incentives</i>. We do not provide any financial incentives tied to the collection, sale, or
            deletion of your Personal Data.
          </div>
        </div>
        <div className={ 'mt-3' }>
          If you would like to make a request and exercise your rights described above,
          please complete our online <a href={'/contact'}>form</a>,
          or contact us via the telephone number listed in the section below.
        </div>
      </Col>
    </Row>
  )
}