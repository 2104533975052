import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header/Header';

export const AgreementsScreen: React.FC = () => {
  const navigate = useNavigate();

  return (
    <section>
      <Header />
      <Container className={'pt-4'}>
        <Row className={'justify-content-center'}>
          <Col sm={6} className={'text-start'}>
            <div className={'mt-4'}>
              <h3><strong>Agreements</strong></h3>
            </div>
          </Col>
        </Row>
        <Row className={'justify-content-center mt-5'}>
          <Col sm={6} className={'text-start'}>
            <Table hover>
              <tbody>
              <tr className={ 'cursor-pointer' }>
                <td className={ 'pt-3 pb-3' } onClick={ () => navigate('/dpa') }>
                  <Row>
                    <Col sm={ 10 }>
                      <h6 className={ 'link-text' }>Data Processing Agreement</h6>
                      <div>
                      </div>
                    </Col>
                    <Col sm={ 2 } className={ 'd-flex justify-content-end align-items-center' }>
                      <FontAwesomeIcon icon={ faChevronRight } className={ 'link-text' }/>
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr className={ 'cursor-pointer' }>
                <td className={ 'pt-3 pb-3' } onClick={ () => navigate('/msa') }>
                  <Row>
                    <Col sm={ 10 }>
                      <h6 className={ 'link-text' }>Master Subscription Agreement</h6>
                      <div>
                      </div>
                    </Col>
                    <Col sm={ 2 } className={ 'd-flex justify-content-end align-items-center' }>
                      <FontAwesomeIcon icon={ faChevronRight } className={ 'link-text' }/>
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr className={ 'cursor-pointer' }>
                <td className={ 'pt-3 pb-3' } onClick={ () => navigate('/security') }>
                  <Row>
                    <Col sm={ 10 }>
                      <h6 className={ 'link-text pt-2' }>Security & Data Privacy</h6>
                      <div>
                      </div>
                    </Col>
                    <Col sm={ 2 } className={ 'd-flex justify-content-end align-items-center' }>
                      <FontAwesomeIcon icon={ faChevronRight } className={ 'link-text' }/>
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr className={ 'cursor-pointer' }>
                <td className={ 'pt-3 pb-3' } onClick={ () => navigate('/sub-processors') }>
                  <Row>
                    <Col sm={ 10 }>
                      <h6 className={ 'link-text pt-2' }>Sub-Processors</h6>
                      <div>
                      </div>
                    </Col>
                    <Col sm={ 2 } className={ 'd-flex justify-content-end align-items-center' }>
                      <FontAwesomeIcon icon={ faChevronRight } className={ 'link-text' }/>
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr className={ 'cursor-pointer' }>
                <td className={ 'pt-3 pb-3' } onClick={ () => navigate('/privacy-policy') }>
                  <Row>
                    <Col sm={ 10 }>
                      <h6 className={ 'link-text pt-2' }>Privacy Policy</h6>
                      <div>
                      </div>
                    </Col>
                    <Col sm={ 2 } className={ 'd-flex justify-content-end align-items-center' }>
                      <FontAwesomeIcon icon={ faChevronRight } className={ 'link-text' }/>
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr className={ 'cursor-pointer' }>
                <td className={ 'pt-3 pb-3' } onClick={ () => navigate('/terms') }>
                  <Row>
                    <Col sm={ 10 }>
                      <h6 className={ 'link-text pt-2' }>Terms of Service</h6>
                      <div>
                      </div>
                    </Col>
                    <Col sm={ 2 } className={ 'd-flex justify-content-end align-items-center' }>
                      <FontAwesomeIcon icon={ faChevronRight } className={ 'link-text' }/>
                    </Col>
                  </Row>
                </td>
              </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
