import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_BASE_URL } from '../config';

const client = (() => {
  return axios.create({
    baseURL: API_BASE_URL,
  });
})();

const request = async (options: AxiosRequestConfig<any>) => {
  const onSuccess = (response: AxiosResponse) => {
    return response;
  };

  const onError = (error: AxiosError) => {
    return Promise.reject(error.response);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;