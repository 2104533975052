import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const DataDisclosed: React.FC<{ elemRef: any }> = ({elemRef}) => {

  const scrollToElement = () => {
    elemRef.current.scrollIntoView({behavior: 'smooth', inline: 'start'});
  }

  return (
    <Row className={ 'justify-content-center mt-5' }>
      <Col sm={ 6 } className={ 'text-justify' }>
        <h6><strong>V. Personal Data Disclosed by Intramark and Data You Choose to Disclose to Other
          Entities</strong></h6>
        <h6 className={ 'mt-3' }><strong>Personal Data Disclosed by Intramark</strong></h6>
        <div>
          In the course of doing business (and over the 12 months preceding the effective date of this Privacy
          Policy),
          Intramark may disclose Personal Data as described below.
        </div>
        <div className={ 'mt-3' }>
          <strong>Service Providers</strong>. For all categories of information that we collect, we disclose
          Personal Data to our service providers for various business purposes, including, but not limited to,
          auditing interactions with users, debugging our websites, products and services, security purposes,
          internal research and gleaning insights through machine learning, short-term uses such as credit
          verification, payment processing, IT services, quality control and safety, gift fulfillment, in-person and
          virtual event management, as well as to perform other services on our behalf. For example, we may use
          service providers to help us proctor and score Intramark certification exams, or host our customer
          relationship management system.
        </div>
        <div className={ 'mt-3' }>
          <strong>In-person and Virtual Events</strong>. If you choose to register for or attend a virtual or
          in-person event or webinar that we host, enter a contest or raffle with us and a sponsor, or download
          content (such as a whitepaper) from our website, then we may disclose your contact information, content
          interest information or other activity data, and any other information, including Personal Data, collected
          in the course of these activities for commercial purposes to those sponsors. In many cases, you
          intentionally disclose your details by providing your information to these sponsors through consent via a
          registration form or by scanning your badge at the applicable sponsor’s booth. Virtual events hosted by
          third party platforms may also collect additional data from you when you visit their sites. The treatment
          of this information is subject to each of these other entities’ respective privacy statements.
        </div>
        <div className={ 'mt-3' }>
          <strong>Partners and Resellers</strong>. We may disclose your Personal Data, such as contact information,
          business details, and content interest and activity details, to our partners and resellers for business
          purposes, such as to carry out our business or for joint marketing efforts to reach our customers and
          prospective customers. In many cases, you intentionally disclose your details by providing your
          information to these sponsors through consent via a registration form.
        </div>
        <div className={ 'mt-3' }>
          <strong>Protection of Rights, Security and Fraud Detection</strong>. For all categories of data we
          collect, we disclose your Personal Data for business purposes to protect our customers and users, to
          secure our physical and intellectual property, and to prevent or investigate security or fraudulent
          attempts against our users through our platform.
        </div>
        <div className={ 'mt-3' }>
          <strong>Law Enforcement and Legal Requests</strong>. For all categories of data we collect, we may
          disclose Personal Data to comply with applicable law or to respond to valid legal requests, such as a
          subpoena, from law enforcement or other authorities.
        </div>
        <div className={ 'mt-3' }>
          <strong>
            With our Affiliates, Related to Corporate Transactions, and Provision of Professional Services
          </strong>. For all categories of data we collect, we disclose Personal Data to our affiliates and
          subsidiaries for business purposes, including any service providers and agents that work on our behalf.
          For example, we may disclose your Personal Data to support service providers with whom we have in place
          agreements to protect your Personal Data. We may also disclose your information as required for us to
          carry out a corporate transaction, such as a merger or sale of assets of all or part of our company. We
          will also disclose your Personal Data to our professional service providers (for example, our auditors,
          insurance providers, financial service providers, and legal advisors) as needed for us to run our
          business.
        </div>
        <div className={ 'mt-3' }>
          <strong>Platform, Training, and Community Analytics Data</strong>. We disclose Ancillary Data, such as
          metadata (for example, unique identifiers and Usage Data), collected through our platform with analytics
          service providers for our business purposes, such as to provide a better user experience and improve our
          products and services.
        </div>
        <div className={ 'mt-3' }>
          <strong>Advertising and Marketing</strong>. We share your Personal Data, such as metadata and contact
          data, with third-party advertising and marketing providers, to allow us to better reach our customers and
          prospective customers, and to sell our products and services. In some circumstances, we may ask you to
          consent to directly disclosing your Personal Data with these third parties prior to sharing your Personal
          Data, such as via a consent banner on our website. For details on your choices with respect this sharing
          or disclosure, please see <span className={ 'link-text' } onClick={ scrollToElement }>
          Your Information Choices</span> below. For specific details on these companies’ privacy
          practices, please visit their privacy policies.
        </div>
        <div className={ 'mt-3' }>
          <strong>Anonymous or De-identified Usage Data</strong>. We disclose anonymized or aggregated usage data or
          security threat information with third parties or the public. For example, this may include disclosing
          trends regarding organizations’ use of Intramark’s products and services to customers and prospective
          customers in our newsletter. The data disclosed in this category is not Personal Data.
        </div>
        <div className={ 'mt-3' }>
          <strong>Intramark Communities, Help Center, and Other User Generated Content</strong>. We make available
          various community forums and self-help support materials, as well as blogs and other means for you to post
          information on our websites. This information you post is publicly-available information that you choose
          to disclose and it may be read, collected, and processed by others that visit these websites. Except for
          username (which may be your real name) and the details that you choose to include in your profile, the
          categories of data disclosed in these circumstances will depend on what information you choose to provide.
        </div>
        <div className={ 'mt-3' }>
          <strong>Recruitment Data</strong>. When you apply for a job at Intramark, we disclose your Personal Data,
          including applicant data, biographical information, and other Personal Data we possess to our affiliate
          companies for business reasons, such as human resource management and internal reporting; our service
          providers for business reasons, such as the recruitment platform, to verify references and to manage
          background checks; and law enforcement or government authorities, or as otherwise necessary to comply with
          law or as needed for the recruitment and human resources process.
        </div>
        <div className={ 'mt-5' }>
          <h6><strong>Intramark Social Media Outlets</strong></h6>
          <div>
            Intramark’s websites may use social media features, such as the Facebook “like” button, the Instagram
            “heart” button, Twitter sharing features, and other similar widgets (“Social Media Features”). You may be
            given the option by such Social Media Features to post information about your activities on a website to a
            profile page of yours that is provided by a third-party social media network in order to share content with
            others within your network. Social Media Features are either hosted by the respective social media network,
            or hosted directly on our websites. To the extent the Social Media Features are hosted by the respective
            social media networks and you click through to these from our website, the latter may receive information
            showing that you have visited our website. If you are logged in to your social media account, it is possible
            that the respective social media network can link your visit to our websites with your social media profile.
          </div>
          <div className={ 'mt-3' }>
            Your interactions with Social Media Features are governed by the privacy policies (and any other applicable
            terms) of the respective companies that provide the relevant Social Media Features.
          </div>
        </div>
        <div className={ 'mt-5' }>
          <h6><strong>Products and Services Available Through Other Channels</strong></h6>
          <div>
            Intramark’s websites may use social media features, such as the Facebook “like” button, the Instagram
            “heart” button, Twitter sharing features, and other similar widgets (“Social Media Features”). You may be
            given the option by such Social Media Features to post information about your activities on a website to a
            profile page of yours that is provided by a third-party social media network in order to share content with
            others within your network. Social Media Features are either hosted by the respective social media network,
            or hosted directly on our websites. To the extent the Social Media Features are hosted by the respective
            social media networks and you click through to these from our website, the latter may receive information
            showing that you have visited our website. If you are logged in to your social media account, it is possible
            that the respective social media network can link your visit to our websites with your social media profile.
          </div>
        </div>
      </Col>
    </Row>
  )
}