import React from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { Header } from '../../components/Header/Header';

export const VibeInstallationScreen: React.FC = () => {
  return (
    <section>
      <Header/>
      <Container className={ 'pt-4 pb-5' }>
        <Row className={ 'justify-content-center' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <div className={ 'mt-4' }>
              <h3><strong>Application Installation & Security</strong></h3>
            </div>
            <div>
              <Badge bg={ 'light' } text={ 'secondary' }>Last Updated: May 1, 2023</Badge>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <div>
              Vibe<span className={ 'font-primary' }>^</span> is a desktop application that schedules and opens a modal
              window for users to interact with a web application. To ensure a smooth and secure installation
              and use of Vibe<span className={ 'font-primary' }>^</span>, please review the following information:
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>Application Installation</strong></h6>
            <div className={ 'mt-3' }>
              <ol>
                <li>
                  Intramark<span className={ 'font-primary' }>^</span> will provide Organization with a Vibe<span
                  className={ 'font-primary' }>^</span> Setup exe file that is compatible with Windows machines x32 bit
                  and x64 bit or dmg file for MacOS machines.
                </li>
                <li className={ 'pt-2' }>
                  The Vibe<span className={ 'font-primary' }>^</span> application is supported to be installed via
                  Mobile Device Management software that Organization’s IT department uses for application auditing and
                  installation. If needed, Intramark<span className={ 'font-primary' }>^</span> can work with
                  Organization’s IT department to set up the application per their requirements.
                </li>
                <li className={ 'pt-2' }>
                  For the Vibe<span className={ 'font-primary' }>^</span> application to work,
                  the website with the URL <br/> <a href={ '' }>https://vibe.intramark.io</a> needs to
                  be allowed in the organization's web filtering or firewall settings.
                </li>
                <li className={ 'pt-2' }>
                  The website makes calls to back-end APIs deployed on the URL <br/> <a href={ '' }>
                  https://vibe-backend.intramark.io</a>, which also needs to be allowed.
                </li>
                <li className={ 'pt-2' }>
                  Once the application is installed, Intramark<span className={ 'font-primary' }>^</span> will send
                  end-users a 6-character activation code to their work email.
                </li>
                <li className={ 'pt-2' }>
                  The Vibe<span className={ 'font-primary' }>^</span> application activation window will automatically
                  pop-up on the end-user's work laptop for them to enter their unique activation code. To start using
                  the application, the end-user will need to enter their unique access code provided to them via their
                  work email.
                </li>
                <li className={ 'pt-2' }>
                  Whenever a new version is launched for the application, Organization would have to re-install the
                  application using the exe/dmg file provided by Intramark<span className={ 'font-primary' }>^</span>.
                  There will be no need to uninstall the previous version, as re-installing the new version would
                  automatically update the application. Note: Uninstalling the application would lead to the removal of
                  the end-user's unique access code stored on the end-user's work laptop. If this happens, Organization
                  can contact Intramark<span className={ 'font-primary' }>^</span> support at <a
                  href={ 'mailTo:support@intramark.io' }>support@intramark.io</a> to restore the same access code or
                  regenerate a new access code for any user.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>Data Collection</strong></h6>
            <div className={ 'mt-3' }>
              To support Vibe<span className={ 'font-primary' }>^</span> standard reporting for Organization,
              Intramark<span className={ 'font-primary' }>^</span> will collect the following end-user
              information below, which should be provided by Organization. Organization may customize the list below
              and/or provide Intramark<span className={ 'font-primary' }>^</span> other fields to support additional
              reporting needs.
            </div>
            <div className={ 'mt-3' }>
              <ol>
                <li>Employee ID</li>
                <li>First Name</li>
                <li>Last Name</li>
                <li>Work Email</li>
                <li>Job Title</li>
                <li>Internal Job Level</li>
                <li>Hire Date</li>
                <li>Manager Work Email</li>
                <li>Job Class (e.g., 30-39 Hour, 20-29 Hour)</li>
                <li>Team Name</li>
                <li>Department Name</li>
                <li>Department Type (e.g., customer-facing, support)</li>
                <li>Office Location</li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>Data Storage</strong></h6>
            <div className={ 'mt-3' }>
              <ol>
                <li>
                  The above-mentioned end-user information is stored in the Intramark<span
                  className={ 'font-primary' }>^</span> Amazon Web Services (AWS) Cloud Database Instance.
                </li>
                <li>
                  End-user feedback response raw data is stored in the Intramark<span
                  className={ 'font-primary' }>^</span> AWS Cloud Database Instance.
                </li>
                <li>
                  End-user unique access code gets stored locally on the end-user's exe application installed on their
                  work laptop.
                </li>
                <li>
                  No other system or end-user data is collected by the Vibe<span
                  className={ 'font-primary' }>^</span> application apart from the ones mentioned above or that is
                  authorized by Organization.
                </li>
                <li>
                  AWS Cloud Database Instance is created under Intramark<span
                  className={ 'font-primary' }>^</span>'s own AWS account and under its own Virtual Private Cloud (VPC),
                  which will restrict any unauthorized user to access the data.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>Data Security</strong></h6>
            <div className={ 'mt-3' }>
              <ol>
                <li>
                  <strong>At Rest:</strong> AWS Cloud Database Instance is created under Intramark<span
                  className={ 'font-primary' }>^</span>'s own AWS account and under its own VPC, which will restrict any
                  unauthorized user to access the data. To access aggregated data from the system, Vibe<span
                  className={ 'font-primary' }>^</span> Public Application Programming Interface (APIs) use OAuth2.0
                  standard specifications for authentication and authorization, ensuring that the data is highly secured
                  and only accessible via provided authentication and authorization details. You can find Intramark<span
                  className={ 'font-primary' }>^</span>Public APIs documentation at <a
                  href={ 'https://api.intramark.io/' } target={ '_blank' }>https://api.intramark.io/.</a>
                </li>
                <li>
                  <strong>In Transit:</strong> Vibe<span className={ 'font-primary' }>^</span> uses HTTPS secure
                  protocol to communicate data between the Vibe<span className={ 'font-primary' }>^</span> application
                  and backend systems.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>AWS Services</strong></h6>
            <div className={ 'mt-3' }>
              We use various AWS services, including VPC (for security), Security Groups (to restrict access to internal
              services only), ECS (with Fargate), ECR, Load Balancers, Lambda, SES, S3, CloudFront, Route 53, and RDS
              (for data storage).
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>Multi-Tenant Architecture</strong></h6>
            <div className={ 'mt-3' }>
              The Vibe<span className={ 'font-primary' }>^</span> application features a high degree multi-tenant
              architecture, which means that multiple
              tenants/organizations use the same backend and data storage layers. Our application secures data for all
              organizations and only allows users to read their own data. Public Analytics APIs also provide aggregated
              data exclusively for authenticated organizations.
              <br/><br/>
              If any organization wants a single tenant architecture for more security, we can set it up with an added
              operational cost.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>Pentest</strong></h6>
            <div className={ 'mt-3' }>
              We perform penetration testing at least once per year or whenever there is a significant upgrade or
              modification in the infrastructure and applications to ensure more consistent IT and network security
              management. This reveals how newly discovered threats or emerging vulnerabilities might be exploited by
              malicious hackers.
            </div>
          </Col>
        </Row>
        <Row className={ 'justify-content-center mt-5' }>
          <Col sm={ 6 } className={ 'text-justify' }>
            <h6><strong>SOC2 Audit</strong></h6>
            <div className={ 'mt-3' }>
              We perform SOC 2 Type II audits at least once per year or whenever there is a significant upgrade or
              modification in our application or related systems to ensure compliance and maintain customer information
              security at all times.
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}