import React, { useEffect, useState } from 'react';
import './HomeScreen.scss';
import { Button, Col, Container, Form, Image, InputGroup, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
import { Subscribe as SubscribeModel } from '../../models/Subscribe';
import SubscribeService from '../../services/SubscribeService';
import { kpi } from '../../utils/images';

export const HomeScreen: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [showSuccess, toggleShowSuccess] = useState(false);
  const [showError, toggleShowError] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      await subscribeToUpdates();
    }
  }

  const subscribeToUpdates = async () => {
    try {
      const subscription: SubscribeModel = {
        email,
      };
      await SubscribeService.saveSubscription(subscription);
      toggleShowSuccess(true);
      setTimeout(() => {
        toggleShowSuccess(false);
      }, 4000);

      setEmail('');
      setValidated(false);
    } catch (error) {
      toggleShowError(true);
      setTimeout(() => {
        toggleShowError(false);
      }, 4000);
    }
  }

  return (
    <section>
      <Header/>
      <section>
        <Container className={ 'main-container' }>
          <Row>
            <Col md={ 12 } className={ 'text-start' }>
              <div style={ {fontSize: '48px'} }>
                <strong>Make better <span className={ 'font-primary' }>business</span> decisions faster</strong>
              </div>
            </Col>
            <Col md={ 5 } sm={ 12 } className={ 'pt-5' }>
              <Row className={ 'text-start' }>
                <Col sm={ 12 } className={ 'font-bold' }>
                  <h5 className={ 'mt-3' }>
                    Our simple, tech-enabled Vibe<span className={ 'font-primary' }>^</span> process provides real-time, differentiated insights so leaders know
                    where to focus limited time and resources for the greatest impact on goals.
                  </h5>
                </Col>
                <Col sm={ 12 } className={ 'mt-3' }>
                  <ul className={ 'ps-3' }>
                    <li><h5>Pinpoint the root cause of why teams are not meeting targets</h5></li>
                    <li><h5>Identify productivity gaps and turnover risks early</h5></li>
                    <li><h5>Measure workload alignment with business priorities</h5></li>
                  </ul>
                  <div>
                    <h5>among other scenarios</h5>
                  </div>
                </Col>
                <Col sm={ 12 }
                     className={ 'font-bold margin-top-100 justify-content-center d-flex flex-column text-center' }>
                  <Button variant="primary" className="btn-block slide-left-right p-3" id="button-addon2"
                          onClick={ () => navigate('/contact') }>
                    <strong style={ {fontSize: '26px'} }>Schedule a call</strong>
                  </Button>
                  <span className={ 'font-normal mt-1 slide-left-right' }>Get actionable insights within 1 day of process start</span>
                </Col>
              </Row>
            </Col>
            <Col md={ 7 } sm={ 12 }>
              <Image
                src={ kpi }
                width={ 'auto' }
                height={ '100%' }
                className={ 'slide-right-left' }
              />
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  );
}
